import { useTranslation } from 'react-i18next';
import { ReactComponent as ToastInfo } from '../../../../../assets/icons/toast-info.svg';
import './studentsToast.scss';

const StudentsToast = ({ type, textKey }) => {
  const { t } = useTranslation();

  const variants = {
    timeRange: {
      1: {
        text: t('last_day_info')
      },
      7: {
        text: t('last_week_info')
      },
      30: {
        text: t('last_month_info')
      },
      90: {
        text: t('last_term_info')
      }
    },
    orderBy: {
      name: {
        text: t('student_order_by_alphabetical_name_info')
      },
      lastname: {
        text: t('student_order_by_alphabetical_lastname_info')
      },
      engagement: {
        text: t('student_order_by_engagement_info')
      },
      masteredContent: {
        text: t('student_order_by_mastered_content_info')
      }
    }
  };

  return (
    <div className='students-toast'>
      <div className='students-toast__icon'>
        <ToastInfo />
      </div>

      <div className='students-toast__message'>
        <div className='students-toast__range'>
          {variants[type][textKey].text}
        </div>
        {type === 'timeRange' && (
          <div className='students-toast__updated'>
            {t('data_updated_real_time')}
          </div>
        )}
      </div>
    </div>
  );
};

export default StudentsToast;
