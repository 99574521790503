import { createColumnHelper } from '@tanstack/react-table';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ColumnTitleWithTooltip from '../../../components/table/cell/cellTypes/ColumnTitleWithTooltip';
import { AnalysisSegment } from '../../../utils/analysis';
import { STUDENT_PAGE_DEFAULT_ORDER_BY } from '../../../utils/constants/student';
import { CourseContext } from '../../../utils/contexts/CourseContext';
import { getStudents } from '../../../utils/crud/student';
import {
  checkIfUserHasVisitedPageBefore,
  markUserAsVisitedPage
} from '../../../utils/helpers/manageUserPageVisits';
import { tryJsonParse } from '../../../utils/helpers/tryJsonParse';
import useAnalytics from '../../../utils/hooks/useAnalytics';
import { useLocalStorage } from '../../../utils/hooks/useLocalStorage';
import { localStorageKeys } from '../../../utils/localStorageKeys';
import CellStudentEngagement from './components/CellStudentEngagement/CellStudentEngagement';
import CellStudentMasteredContent from './components/CellStudentMasteredContent/CellStudentMasteredContent';
import CellStudentNameWithHelpIcon from './components/CellStudentNameWithHelpIcon/CellStudentNameWithHelpIcon';
import CellStudentPerformance from './components/CellStudentPerformance/CellStudentPerformance';
import StudentsToast from './components/StudentsToast/StudentsToast';
import {
  MOCK_DATA_MONTH,
  MOCK_DATA_TERM,
  MOCK_DATA_WEEK
} from './studentsMockData';
import useStudentsDataParsers from './useStudentsDataParsers';
import useStudentsSorting from './useStudentsSorting';

const useStudentsPage = (isMock) => {
  const DEFAULT_TIME_RANGE = 1;

  const location = useLocation();
  const navigate = useNavigate();
  const { setItem, getItem } = useLocalStorage();
  const { selectedCourse } = useContext(CourseContext);
  const { t } = useTranslation();
  const { trackEvent } = useAnalytics();

  const {
    getUserEngagementData,
    getUserPerformanceData,
    getUserMasteredContentData
  } = useStudentsDataParsers();

  const { sortData } = useStudentsSorting();

  const [isLoading, setIsLoading] = useState(false);

  const columnHelper = createColumnHelper();

  const [usersData, setUsersData] = useState([]);
  const [sortedUserList, setSortedUserList] = useState([]);
  const [numberOfKcs, setNumberOfKcs] = useState();
  const [numberOfLastDays, setNumberOfLastDays] = useState();
  const [hasEngagementHours, setHasEngagementHours] = useState(false);
  const [selectedTimeRange, setSelectedTimeRange] = useState();
  const [selectedOrderBy, setSelectedOrderBy] = useState();
  const [selectedTimeRangeTrigger, setSelectedTimeRangeTrigger] =
    useState('pageLoad');
  const [selectedOrderByTrigger, setSelectedOrderByTrigger] =
    useState('pageLoad');
  const [
    hasUserVisitedPageBeforeInSession,
    setHasUserVisitedPageBeforeInSession
  ] = useState(false);

  // Hide toasts when exiting
  // Get initial filters from local storage if they exist of set them to default values
  useEffect(() => {
    let timeRange = DEFAULT_TIME_RANGE;
    let orderBy = STUDENT_PAGE_DEFAULT_ORDER_BY;

    // get filters from local storage if available
    const filterKey = location.pathname;
    let filter = getItem(filterKey);

    if (filter) {
      try {
        filter = tryJsonParse(filter);
        timeRange = filter.timeRange || DEFAULT_TIME_RANGE;
        orderBy = filter.orderBy || STUDENT_PAGE_DEFAULT_ORDER_BY;
      } catch (e) {
        filter = {};
      }
    }

    // to better manage toasts we check if the user has been on this page type before
    const hasUserVisitedBefore = checkIfUserHasVisitedPageBefore(
      localStorageKeys.hasVisitedStudentsAnalysisPage
    );
    setHasUserVisitedPageBeforeInSession(hasUserVisitedBefore);
    if (!hasUserVisitedBefore) {
      markUserAsVisitedPage(localStorageKeys.hasVisitedStudentsAnalysisPage);
    }

    setSelectedTimeRange(timeRange);
    setSelectedOrderBy(orderBy);

    return () => {
      toast.dismiss();
    };
  }, []);

  // Manage change in time range
  useEffect(() => {
    if (!selectedTimeRange) return;
    // Save filters in localstorage
    saveSortedUserAvatarList(sortedUserList);

    toast.dismiss();
    if (
      (selectedTimeRangeTrigger === 'pageLoad' &&
        !hasUserVisitedPageBeforeInSession) ||
      selectedTimeRangeTrigger !== 'pageLoad'
    ) {
      setTimeout(() => {
        toast(<StudentsToast type='timeRange' textKey={selectedTimeRange} />, {
          position: 'bottom-left',
          autoClose: 8000,
          hideProgressBar: true
        });
      }, 300);
    }

    setSelectedTimeRangeTrigger(undefined);
    trackEvent(
      AnalysisSegment.SEGMENT_EVENTS.Students_Analysis_Page_Time_Changed,
      {
        order_by:
          AnalysisSegment.SEGMENT_PROPERTIES_MAPPING
            .Students_Analysis_Page_Order_Changed[selectedOrderBy],
        time_range:
          AnalysisSegment.SEGMENT_PROPERTIES_MAPPING
            .Students_Analysis_Page_Time_Changed[selectedTimeRange]
      }
    );
  }, [selectedTimeRange]);

  // Manage change in order
  useEffect(() => {
    if (!selectedOrderBy) return;
    // Since the ordering is local, we need to sort the data without issuing a new request.
    const sortedUsers = sortData(usersData, selectedOrderBy);
    setUsersData(sortedUsers);

    // Save filters in localstorage
    saveSortedUserAvatarList(sortedUsers);

    toast.dismiss();
    if (
      (selectedOrderByTrigger === 'pageLoad' &&
        !hasUserVisitedPageBeforeInSession) ||
      selectedOrderByTrigger !== 'pageLoad'
    ) {
      setTimeout(() => {
        toast(<StudentsToast type='orderBy' textKey={selectedOrderBy} />, {
          position: 'bottom-left',
          autoClose: 6000,
          hideProgressBar: true
        });
      }, 300);
    }

    setSelectedOrderByTrigger(undefined);
    trackEvent(
      AnalysisSegment.SEGMENT_EVENTS.Students_Analysis_Page_Order_Changed,
      {
        order_by:
          AnalysisSegment.SEGMENT_PROPERTIES_MAPPING
            .Students_Analysis_Page_Order_Changed[selectedOrderBy]
      }
    );
  }, [selectedOrderBy]);

  // Save lighter user list with avatars for inner pages
  const saveSortedUserAvatarList = (sortedUsers) => {
    const courseUsersAvatarList = selectedCourse?.users?.map((courseUser) => {
      const jsonSettings = JSON.parse(courseUser.json_settings);
      return {
        guid: courseUser.person_guid,
        avatar: jsonSettings?.blueberry?.avatar?.single_image
      };
    });

    const mergedSortedUserList = sortedUsers.map(({ guid, name, lastname }) => {
      const courseUser = courseUsersAvatarList.find(
        (courseUser) => courseUser.guid === guid
      );
      return {
        guid,
        name,
        lastname,
        avatar: courseUser ? courseUser.avatar : null
      };
    });

    const filterKey = location.pathname;
    setItem(
      filterKey,
      JSON.stringify({
        timeRange: selectedTimeRange,
        orderBy: selectedOrderBy,
        sortedUserList: mergedSortedUserList
      })
    );
    setSortedUserList(mergedSortedUserList);
  };

  // Go to detail
  const onClickOnStudent = (studentGuid) => {
    navigate(`/${selectedCourse?.guid}/students/${studentGuid}`, {
      state: {
        from: location.pathname
      }
    });
  };

  // Table definition
  const columns = [
    columnHelper.accessor('lastname', {
      id: 'Student',
      header: t('student'),
      enableSorting: false,
      cell: (props) => (
        <CellStudentNameWithHelpIcon
          name={props.row.original?.name}
          lastName={props.row.original?.lastname}
          needsHelpInfo={props.row.original?.needHelpLoPhase}
          onClickOnStudent={() => onClickOnStudent(props.row.original?.guid)}
        />
      )
    }),
    columnHelper.accessor('engagement', {
      header: (
        <ColumnTitleWithTooltip
          title={t('engagement')}
          tooltip={t('engagement_tooltip')}
        />
      ),
      enableSorting: false,
      cell: (props) => (
        <CellStudentEngagement
          selectedTimeRange={selectedTimeRange}
          studentEngagementData={getUserEngagementData(
            props.row.original,
            numberOfLastDays,
            hasEngagementHours
          )}
        />
      )
    }),
    columnHelper.accessor('performance', {
      header: (
        <ColumnTitleWithTooltip
          title={t('performance')}
          tooltip={t('performance_tooltip')}
        />
      ),
      enableSorting: false,
      cell: (props) => (
        <CellStudentPerformance
          studentPerformanceData={getUserPerformanceData(
            props.row.original?.activities
          )}
        />
      )
    }),
    columnHelper.accessor('masteredContent', {
      header: (
        <ColumnTitleWithTooltip
          title={t('mastered_content')}
          tooltip={t('mastered_content_tooltip')}
        />
      ),
      enableSorting: false,
      cell: (props) => (
        <CellStudentMasteredContent
          selectedTimeRange={selectedTimeRange}
          studentMasteredContentData={getUserMasteredContentData(
            props.row.original,
            numberOfKcs
          )}
        />
      )
    })
  ];

  // Helpers
  const apiCallSimulation = (timeRange) => {
    let apiData;
    switch (timeRange) {
      case 7:
        apiData = MOCK_DATA_WEEK;
        break;
      case 30:
        apiData = MOCK_DATA_MONTH;
        break;
      case 90:
        apiData = MOCK_DATA_TERM;
        break;
      default:
        apiData = MOCK_DATA_WEEK;
        break;
    }
    return apiData;
  };

  // Get data
  const getData = async (timeRange, orderBy) => {
    if (isLoading) return;
    setIsLoading(true);

    let tempData = await getStudents({
      courseGuid: selectedCourse?.guid,
      days: timeRange
    });

    let apiData;
    if (isMock) {
      apiData = apiCallSimulation(timeRange);
    } else {
      apiData = tempData || [];
    }

    // Check if there is a user with more than an hour of activity time logged in order to show hours in engagement column
    setHasEngagementHours(
      apiData?.users?.some((user) => user?.totalTimeInSeconds > 3600)
    );
    setNumberOfLastDays(apiData?.numberOfLastDays);
    setNumberOfKcs(apiData?.program?.numberOfKcs);

    // Order users data by filter
    const sortedUsers = sortData(apiData?.users, orderBy);

    // Save sortedUsers in local storage
    saveSortedUserAvatarList(sortedUsers);

    setUsersData(sortedUsers);
    setIsLoading(false);
  };

  // Get initial data
  useEffect(() => {
    if (!selectedCourse?.guid || !selectedTimeRange) return;
    getData(selectedTimeRange, selectedOrderBy);
    trackEvent(AnalysisSegment.SEGMENT_EVENTS.Students_Analysis_Page_Viewed, {
      order_by:
        AnalysisSegment.SEGMENT_PROPERTIES_MAPPING
          .Students_Analysis_Page_Order_Changed[selectedOrderBy],
      time_range:
        AnalysisSegment.SEGMENT_PROPERTIES_MAPPING
          .Students_Analysis_Page_Time_Changed[selectedTimeRange]
    });
  }, [selectedCourse, selectedTimeRange]);

  return {
    usersData,
    columns,
    isLoading: isLoading,
    onClickOnStudent,
    selectedTimeRange,
    setSelectedTimeRange,
    selectedOrderBy,
    setSelectedOrderBy
  };
};

export default useStudentsPage;
