import './CellWithLabelAndProgressBar.scss';

const CellWithLabelAndProgressBar = ({
  label,
  progress,
  color,
  disableHover
}) => {
  return (
    <div className={`cell__container cell--label-with-progress-bar`}>
      <div className={`cell__label cell__label--${color}`}>{label}</div>
      {Boolean(progress) && (
        <div className='cell__progress-bar-container'>
          <div
            className={`cell__progress-bar cell__progress-bar--${color} cell__progress-bar--${
              disableHover ? 'hover-disabled' : 'hover-enabled'
            }`}
            style={{ width: `${progress * 100}%` }}
          />
        </div>
      )}
    </div>
  );
};

export default CellWithLabelAndProgressBar;
