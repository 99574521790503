import { Outlet } from 'react-router-dom';
import WelcomeDialog from './dialogs/WelcomeDialog';
import useOnboarding from './useOnboarding';

const Onboarding = () => {
  const { popupToShow } = useOnboarding();

  return (
    <div className='onboarding-container'>
      {popupToShow === 'welcome' && <WelcomeDialog />}
      <Outlet />
    </div>
  );
};

export default Onboarding;
