import clsx from 'clsx';
import PropTypes from 'prop-types';
import { ReactComponent as BBMathLogo } from '../../../../assets/bb-math-logo.svg';
import { TDTypography } from '../../../../atoms/TDUILib';
import './AuthHeader.scss';

const AuthHeader = ({
  title,
  subTitle,
  subTitleVariant = 'dark',
  className
}) => {
  return (
    <div className={clsx('auth-header__container', className)}>
      <div className='auth-header__header' data-testid='auth-header'>
        <div className='auth-header__header-logo'>
          <BBMathLogo />
        </div>
        <div className='auth-header__header-text-container'>
          <TDTypography
            variant='body-xl'
            fontWeight='semibold'
            className='auth-header__title'
          >
            {title}
          </TDTypography>
          {subTitle && (
            <TDTypography
              variant='body-m'
              fontWeight='medium'
              className={clsx(
                'auth-header__subtitle',
                `auth-header__subtitle--${subTitleVariant}`
              )}
            >
              {subTitle}
            </TDTypography>
          )}
        </div>
      </div>
    </div>
  );
};

AuthHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  subTitleVariant: PropTypes.oneOf(['light', 'dark']),
  className: PropTypes.string,
  testId: PropTypes.string
};

export default AuthHeader;
