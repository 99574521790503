import { teacherDashboardEndpoints } from '../helpers/endpoints';
import { fetchWithoutToken } from '../helpers/fetcher';

export const login = async ({ email, password }) => {
  const path = teacherDashboardEndpoints.login;

  const params = { email, password, admitted_roles: ['R02', 'R03', 'R04'] };

  return fetchWithoutToken({
    method: 'POST',
    path,
    data: params,
    disableCatchHandling: true
  });
};

export const oauthLogin = async ({ data, disableCatchHandling }) => {
  const path = teacherDashboardEndpoints.oauthLogin;

  return fetchWithoutToken({
    method: 'POST',
    path,
    data,
    disableCatchHandling
  });
};

export const sendVerificationEmail = async ({ email, lang }) => {
  return fetchWithoutToken({
    path: 'front/sign-up/generate-verification-code',
    method: 'POST',
    data: {
      email,
      lang
    }
  });
};

export const validateCodeSentByEmail = async ({ email, code }) => {
  return fetchWithoutToken({
    path: 'front/sign-up/validate-verification-code',
    method: 'POST',
    data: {
      email,
      verification_code: code
    }
  });
};

// BBTEC-6559
export const searchForSchools = async ({ search = '' }) => {
  return fetchWithoutToken({
    path: `front/sign-up/schools?pageSize=20&search=${search}&region=europe`,
    method: 'GET'
  });
};

export const registerUser = async ({ data }) => {
  const {
    role,
    firstName,
    lastName,
    email,
    phone,
    responsibility,
    langId,
    schoolGuid,
    isSelfRegistered,
    password,
    countryGuid,
    educationYearGuid,
    identity_provider,
    sso_token
  } = data;

  return fetchWithoutToken({
    path: 'front/sign-up/user',
    method: 'POST',
    data: {
      role: 'teacher',
      name: firstName,
      lastname: lastName,
      email,
      phone,
      responsibility,
      lang_id: langId,
      school_guid: schoolGuid,
      is_self_registered: isSelfRegistered,
      password,
      country_guid: countryGuid,
      education_year_guid: educationYearGuid,
      region: 'europe',
      identity_provider,
      sso_token
    }
  });
};

export const checkUserExists = async ({ email }) => {
  return fetchWithoutToken({
    path: `front/sign-up/user-check`,
    method: 'POST',
    data: {
      email
    }
  });
};
