import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AnalysisSegment } from '../../../utils/analysis';
import {
  checkUserExists,
  oauthLogin,
  registerUser,
  sendVerificationEmail,
  validateCodeSentByEmail
} from '../../../utils/crud/auth';
import {
  validateEmail,
  validateLastName,
  validateName,
  validatePassword
} from '../../../utils/crud/validators';
import useAnalytics from '../../../utils/hooks/useAnalytics';
import useAuth from '../../../utils/hooks/useAuth';
import { RegistrationContext } from './RegistrationContext';

const useRegisterPage = () => {
  const { t, i18n } = useTranslation();
  const { onLoginWithEmailAndPassword, onLoginWithGoogle } = useAuth();

  const {
    currentStep,
    setCurrentStep,
    userCredentials,
    setUserCredentials,
    REGISTRATION_STEPS
  } = useContext(RegistrationContext);

  const { trackRawEvent } = useAnalytics();

  const [sendVerificationCodeError, setSendVerificationCodeError] =
    useState('');
  const [verificationCodeError, setVerificationCodeError] = useState('');
  const [timeOfLastCodeResend, setTimeOfLastCodeResend] = useState(Date.now());
  const [emailAlreadyRegisteredError, setEmailAlreadyRegisteredError] =
    useState(false);
  const [isRegisteringUser, setIsRegisteringUser] = useState(false);

  const validateRegistrationForm = async ({
    email,
    password,
    firstName,
    lastName,
    termsAndConditions
  }) => {
    // local validation
    const errorsDetected = {
      firstName: !validateName(firstName),
      lastName: !validateLastName(lastName),
      email: !validateEmail(email),
      password: !validatePassword(password),
      termsAndConditions: Boolean(!termsAndConditions)
    };

    const errorMessages = {
      firstName: validateName(firstName)
        ? ''
        : t('register_form_account_info_collection_name_error'),
      lastName: validateLastName(lastName)
        ? ''
        : t('register_form_account_info_collection_name_error'),
      email: validateEmail(email)
        ? ''
        : t('register_form_account_info_collection_email_error'),
      password: validatePassword(password)
        ? ''
        : t('register_form_account_info_collection_password_error'),
      termsAndConditions: Boolean(termsAndConditions)
        ? ''
        : t('register_form_account_info_collection_terms_and_conditions_error')
    };

    // if at least one error is detected, return false
    if (Object.values(errorsDetected).some((error) => error === true)) {
      return { status: false, errors: errorsDetected, errorMessages };
    }

    // check if email is already registered
    const response = await checkUserExists({ email });

    if (response.status === 'success' && !response?.data?.is_available) {
      setEmailAlreadyRegisteredError(true);
      errorsDetected.email = true;
      errorMessages.email = t(
        'register_form_account_info_collection_email_already_registered_error'
      );
      return { status: false, errors: errorsDetected, errorMessages };
    }

    // if no errors are detected, return true
    return { status: true, errors: errorsDetected, errorMessages };
  };

  const onRegisterWithEmailAndPassword = async ({
    email,
    password,
    firstName,
    lastName
  }) => {
    // setSendVerificationCodeError('');
    // send email verification code
    // const response = await sendVerificationEmail({
    //   email,
    //   lang: i18n.resolvedLanguage
    // });

    // Error management - specific error for email verification if email is already registered we will not be able to send verification code.
    // if (response?.data?.status === 'fail') {
    //   setSendVerificationCodeError(response?.data?.error?.error);
    //   return;
    // }

    setEmailAlreadyRegisteredError(false);
    const response = await checkUserExists({ email });
    // if user is not available, we will not be able to send verification code
    if (response.status === 'success' && !response?.data?.is_available) {
      // set error message
      setEmailAlreadyRegisteredError(true);
      return;
    }

    // save user credentials in state to create account after verification
    setUserCredentials({ email, password, firstName, lastName });
    // - SKIPPED in MVP - Change step to verification
    // setCurrentStep(REGISTRATION_STEPS.verification);
    // Change step to userInfoCollection
    setCurrentStep(REGISTRATION_STEPS.userInfoCollection);
  };

  const onRegisterWithGoogle = async ({ credential }) => {
    // save user credentials in state to create account after verification
    setUserCredentials({ sso_token: credential, identity_provider: 'google' });

    // attempt to log the user in with google to check if the user is already registered
    // TODO: replace with check-user-exists endpoint when it's ready
    const response = await oauthLogin({
      data: {
        identity_provider: 'google',
        token: credential
      },
      disableCatchHandling: true
    });

    if (response.status === 'success') {
      onLoginWithGoogle({ credential });
    } else {
      setCurrentStep(REGISTRATION_STEPS.userInfoCollection);
    }
  };

  // Email Verification
  const onResendCode = async () => {
    setTimeOfLastCodeResend(Date.now());
    await sendVerificationEmail({
      email: userCredentials.email,
      lang: i18n.resolvedLanguage
    });
  };

  const onValidateCode = async (code) => {
    const response = await validateCodeSentByEmail({
      email: userCredentials.email || 'test@test.com',
      code
    });

    if (response.status === 'success') {
      setVerificationCodeError('');
      // register user
      setCurrentStep(REGISTRATION_STEPS.userInfoCollection);
    } else {
      setVerificationCodeError(t('register_form_email_verification_error'));
    }
  };

  const createUserAndLogin = async ({
    responsibility,
    schoolGuid,
    educationYearGuid,
    educationLevelName,
    educationYearName,
    schoolName
  }) => {
    setIsRegisteringUser(true);
    let data = {
      // optional field
      ...(responsibility && { responsibility }),
      // required fields
      ...userCredentials,
      schoolGuid,
      educationYearGuid,
      langId: i18n.resolvedLanguage,
      // default fields
      role: 'teacher',
      isSelfRegistered: 1,
      // tenant based field
      countryGuid: 'es'
    };

    const response = await registerUser({ data });

    if (response?.status === 'success') {
      trackRawEvent(AnalysisSegment.SEGMENT_EVENTS.Sign_Up_Completed, {
        school_name: schoolName,
        position: responsibility,
        education_level: educationLevelName,
        education_year: educationYearName,
        provider: userCredentials.identity_provider || 'userpass'
      });
      // set flag in local storage to trigger that the user is registered
      localStorage.setItem('bb_enable_welcome_onboarding', true);
      if (userCredentials.identity_provider === 'google') {
        await onLoginWithGoogle({
          credential: userCredentials.sso_token
        });
      } else {
        await onLoginWithEmailAndPassword({
          email: userCredentials.email,
          password: userCredentials.password
        });
      }
    } else {
      trackRawEvent(AnalysisSegment.SEGMENT_EVENTS.Sign_Up_Failed);
      setIsRegisteringUser(false);
    }
  };

  return {
    currentStep,
    REGISTRATION_STEPS,
    userCredentials,
    onRegisterWithEmailAndPassword,
    sendVerificationCodeError,
    onResendCode,
    onValidateCode,
    timeOfLastCodeResend,
    createUserAndLogin,
    verificationCodeError,
    onRegisterWithGoogle,
    emailAlreadyRegisteredError,
    validateRegistrationForm,
    isRegisteringUser
  };
};

export default useRegisterPage;
