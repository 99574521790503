import { Autocomplete, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { CourseContext } from '../../utils/contexts/CourseContext';
import { getCourseDepartments } from '../../utils/crud/content';
import { getBBTeacherUserLanguage } from '../../utils/helpers/userLanguage';
import { useLocalStorage } from '../../utils/hooks/useLocalStorage';

const TDDepartmentsFilter = ({ onChange = () => {}, defaultValue = '' }) => {
  const { t } = useTranslation();
  const { selectedCourse } = useContext(CourseContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const { getItem, setItem, removeItem } = useLocalStorage();

  const allDepartmentsValue = {
    value: 'all',
    label: t('all_departments'),
    guid: 'all',
    disabled: false
  };

  const [isLoading, setIsLoading] = useState(true);
  const [departments, setDepartments] = useState([allDepartmentsValue]);
  const [selectedDepartment, setSelectedDepartment] =
    useState(allDepartmentsValue);

  useEffect(() => {
    setIsLoading(true);
    if (!selectedCourse?.guid) return;

    getDepartments();
  }, [selectedCourse]);

  useEffect(() => {
    if (departments?.length === 1) return;

    if (getItem(location.pathname)) {
      setSelectedDepartment(
        departments?.filter((department) => {
          let _standard;
          try {
            _standard = JSON.parse(getItem(location.pathname))?.standard;
          } catch (error) {
            _standard = null;
            console.error(error);
          }
          return department.guid === _standard;
        })[0] || departments[0]
      );
    }
  }, [departments]);

  const getDepartments = async () => {
    const tempDepartments = await getCourseDepartments({
      courseGuid: selectedCourse?.guid,
      lang: getBBTeacherUserLanguage()
    });

    if (defaultValue) {
      setSelectedDepartment(
        tempDepartments?.filter(
          (department) => department.guid === defaultValue
        )[0]
      );
    } else {
      setSelectedDepartment(allDepartmentsValue);
    }

    setDepartments([
      allDepartmentsValue,
      ...(Array.isArray(tempDepartments)
        ? tempDepartments.map((item) => ({
            ...item,
            value: item?.guid,
            label: item?.name,
            disabled: Boolean(item.status === 'draft')
          }))
        : [])
    ]);

    setIsLoading(false);
  };

  useEffect(() => {
    if (selectedDepartment === '' || departments.length === 1) return;

    if (selectedDepartment?.value === 'all') {
      setSearchParams({});
      removeItem(location.pathname);
    } else {
      setItem(
        location.pathname,
        JSON.stringify({ standard: selectedDepartment?.guid })
      );
      setSearchParams({
        standard: selectedDepartment?.guid
      });
    }
  }, [selectedDepartment]);

  return (
    <Autocomplete
      loading={isLoading}
      value={selectedDepartment}
      onChange={(_, newValue) => {
        setSelectedDepartment(newValue);
      }}
      id='units-selector'
      options={departments}
      getOptionDisabled={(option) => option.disabled}
      getOptionLabel={(option) => option.label}
      multiple={false}
      disableClearable
      sx={{ width: 250 }}
      renderInput={(params) => <TextField {...params} />}
    />
  );
};

TDDepartmentsFilter.propTypes = {
  onChange: PropTypes.func,
  defaultValue: PropTypes.string
};

export default TDDepartmentsFilter;
