import { KeyboardDoubleArrowRight, Menu } from '@mui/icons-material';

import { Skeleton } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as ArrowLeft } from '../../../../../assets/icons/outline/arrow-left.svg';
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/x.svg';
import { TDHeaderButton } from '../../../../../atoms/TDUILib';
import { AnalysisSegment } from '../../../../../utils/analysis';
import { useCourse } from '../../../../../utils/contexts/CourseContext';
import { useStudent } from '../../../../../utils/contexts/StudentContext';
import useAnalytics from '../../../../../utils/hooks/useAnalytics';
import useAuth from '../../../../../utils/hooks/useAuth';
import StudentDetailsMenu from '../studentDetailsMenu/StudentDetailsMenu';
import StudentInterventionsValidationDialog from '../studentInterventionsValidationDialog/StudentInterventionsValidationDialog';
import './StudentDetailsTopBar.scss';

const StudentDetailsTopBar = ({ isSidebarOpen, setIsSidebarOpen }) => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { selectedCourse } = useCourse();
  const { trackEvent } = useAnalytics();
  const { user } = useAuth();

  const { student, studentGuid, isLoadingStudent, studentInterventions } =
    useStudent();
  const [hasInterventionWithoutFeedback, setHasInterventionWithoutFeedback] =
    useState(false);
  const [isValidationDialogOpen, setIsValidationDialogOpen] = useState(false);

  // used to detect which page the user was on before opening the student details page in order to return to it
  // This state should only track the full page of the student details page, not the sub pages
  // The login before tracks 'from' state on the location which the pages navigating here should provide or else the default page (/students) will be used
  const [fromWhichPage, setFromWhichPage] = useState(null);

  useEffect(() => {
    if (location.state?.from) {
      setFromWhichPage(location.state.from);
    }
  }, [location.state]);

  useEffect(() => {
    const tempInterventionsToValidate = (studentInterventions || [])?.filter(
      (intervention) => intervention.intervention_validation === 'pending'
    );

    setHasInterventionWithoutFeedback(tempInterventionsToValidate.length > 0);
  }, [studentInterventions]);

  // this is used temporarily to detect if we are on a lesson page.
  // When we add more pages we will have a better condition to detect which page the user was on before opening the student details page
  const isLessonPage = params.lessonGuid;

  // update handling when adding more tabs and sub pages

  const handleGoBackToInterventions = () => {
    navigate(`/${selectedCourse?.guid}/students/${studentGuid}/interventions`);
  };

  const handleGoBack = ({ skipValidation = false }) => {
    // check if there are interventions without feedback
    if (hasInterventionWithoutFeedback && !skipValidation) {
      setIsValidationDialogOpen(true);
      trackEvent(AnalysisSegment.SEGMENT_EVENTS.Validation_Pop_up_Seen);
    } else {
      if (!isLessonPage && fromWhichPage) {
        navigate(fromWhichPage);
      } else {
        navigate(`/${selectedCourse?.guid}/students`);
      }
    }
  };

  const onClickOnBurgerButton = () => {
    setIsSidebarOpen(true);
  };

  return (
    <div className='student-details-topbar'>
      <div className='student-details-topbar__left'>
        <div
          className={`student-details-topbar__menu-container ${
            isSidebarOpen
              ? `student-details-topbar__menu-container--hidden`
              : `student-details-topbar__menu-container--visible`
          } ${
            isLessonPage
              ? 'student-details-topbar__menu-container--with-border'
              : 'student-details-topbar__menu-container--no-border'
          }`}
        >
          <div className='student-details-topbar__menu-container--menu-button'>
            <button
              className='student-details__button'
              onClick={onClickOnBurgerButton}
            >
              <Menu className='student-details-topbar__menu-icon--burger' />
              <KeyboardDoubleArrowRight className='student-details-topbar__menu-icon--arrow' />
            </button>
            <div className='student-details-topbar__menu'>
              <StudentDetailsMenu />
            </div>
          </div>
          <div className='student-details-topbar__menu-name'>
            {student &&
              !isLoadingStudent &&
              `${student.name} ${student.lastname}`}
            {isLoadingStudent && <Skeleton variant='text' width={100} />}
          </div>
        </div>
      </div>

      <div className='student-details-topbar__right'>
        <TDHeaderButton
          onClick={() => handleGoBack({ skipValidation: false })}
          icon={<CloseIcon />}
        />
        {isLessonPage && (
          <TDHeaderButton
            onClick={handleGoBackToInterventions}
            icon={<ArrowLeft />}
          />
        )}
      </div>

      <StudentInterventionsValidationDialog
        isOpen={isValidationDialogOpen}
        callback={() => handleGoBack({ skipValidation: true })}
        onClose={() => {
          if (user?.devmode && user?.is_demo) {
            setIsValidationDialogOpen(false);
          }
        }}
      />
    </div>
  );
};

export default StudentDetailsTopBar;
