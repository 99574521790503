import { createContext, useState } from 'react';

const LIST_OF_POPUPS = {
  welcome: {
    trigger: 'localstorage',
    key: 'bb_enable_welcome_onboarding',
    value: 'true' // value is a string because we need to compare it with the value in local storage and the value is a string in local storage
  }
};

const OnboardingContext = createContext({
  LIST_OF_POPUPS
});

const OnboardingProvider = ({ children }) => {
  const [popupToShow, setPopupToShow] = useState(null);

  return (
    <OnboardingContext.Provider
      value={{ popupToShow, setPopupToShow, LIST_OF_POPUPS }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};

export { OnboardingContext, OnboardingProvider };
