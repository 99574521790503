import { useContext, useEffect, useState } from 'react';
import { OnboardingContext } from '../../../utils/contexts/OnboardingContext';
import { useLocalStorage } from '../../../utils/hooks/useLocalStorage';

const useOnboarding = () => {
  const { getItem } = useLocalStorage();
  const { popupToShow, setPopupToShow, LIST_OF_POPUPS } =
    useContext(OnboardingContext);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // in order to detect triggers, we need to check the url and the number of visits
  useEffect(() => {
    // loop through the keys of the list of popups and check if the trigger is met
    Object.keys(LIST_OF_POPUPS).forEach((popup) => {
      const { trigger, key, value } = LIST_OF_POPUPS[popup];

      if (trigger === 'localstorage') {
        if (getItem(key) === value) {
          console.log('popup', popup);
          setPopupToShow(popup);
          setIsDialogOpen(true);
        }
      }
    });
  }, []);

  return { popupToShow, setPopupToShow, isDialogOpen, setIsDialogOpen };
};

export default useOnboarding;
