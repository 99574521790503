import clsx from 'clsx';
import './TDInputGroup.scss';

const TDInputGroup = ({ label, children, className, hasError, errorText }) => {
  return (
    <div
      className={clsx('td-input-group', {
        [className]: className,
        'td-input-group--error': hasError
      })}
    >
      <div className='td-input-group__label'>{label}</div>
      <div className='td-input-group__children'>{children}</div>
      {hasError && <div className='td-input-group__error'>{errorText}</div>}
    </div>
  );
};

export default TDInputGroup;
