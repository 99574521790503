import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PlusCircleIcon, XCircleIcon } from '../../assets/icons';
import iconFlagSa from '../../assets/icons/flags/as-flag.svg';
import iconFlagBr from '../../assets/icons/flags/br-flag.svg';
import iconFlagEu from '../../assets/icons/flags/eu-flag.svg';
import iconFlagMx from '../../assets/icons/flags/mx-flag.svg';
import iconFlagRd from '../../assets/icons/flags/rd-flag.svg';
import {
  TDButton,
  TDDialog,
  TDSelect,
  TDTypography
} from '../../atoms/TDUILib';
import { AnalysisSegment } from '../../utils/analysis';
import consoleLog from '../../utils/helpers/consoleLog';
import useAnalytics from '../../utils/hooks/useAnalytics';
import './regionSelect.scss';
import useRegionSelect from './useRegionSelect';

const GEOLOCATION_SERVICE_URL =
  'https://geo.blueberrymath.ai/country-guesser/guess';
const COUNTDOWN_IN_SECONDS = 10;
const LOCALHOST_DOMAIN = 'dashboard.school.blueberrymath.com.mx';

const RegionSelect = () => {
  const { trackEvent } = useAnalytics();
  const { t } = useTranslation();
  const {
    redirectToRegion,
    translateCountry,
    savePromptChecked,
    getPromptChecked
  } = useRegionSelect(LOCALHOST_DOMAIN);
  const [isRedirectDialogOpen, setIsRedirectDialogOpen] = useState(false);
  const [redirectTimer, setRedirectTimer] = useState(null);
  const [countdown, setCountdown] = useState(COUNTDOWN_IN_SECONDS);

  const [selectedRegionData, setSelectedRegionData] = useState(null);
  const [currentCountry, setCurrentCountry] = useState(null);
  const [currentCountryCode, setCurrentCountryCode] = useState(null);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const RegionSelectItem = ({ label, icon }) => (
    <div className='region-select__item'>
      <img src={icon} alt={label} className='region-select__item-flag' />
      <div className='region-select__item-label'>{label}</div>
    </div>
  );

  const regionsData = [
    {
      value: 'eu',
      text: t('region_select_option_eu'),
      label: (
        <RegionSelectItem
          label={t('region_select_option_eu')}
          icon={iconFlagEu}
        />
      ),
      endsWith: '.ai'
    },
    {
      value: 'sa',
      text: t('region_select_option_sa'),
      label: (
        <RegionSelectItem
          label={t('region_select_option_sa')}
          icon={iconFlagSa}
        />
      ),
      endsWith: '.com'
    },
    {
      value: 'do',
      text: t('region_select_option_rd'),
      label: (
        <RegionSelectItem
          label={t('region_select_option_rd')}
          icon={iconFlagRd}
        />
      ),
      endsWith: '.com'
    },
    {
      value: 'br',
      text: t('region_select_option_br'),
      label: (
        <RegionSelectItem
          label={t('region_select_option_br')}
          icon={iconFlagBr}
        />
      ),
      endsWith: '.com'
    },
    {
      value: 'mx',
      text: t('region_select_option_mx'),
      label: (
        <RegionSelectItem
          label={t('region_select_option_mx')}
          icon={iconFlagMx}
        />
      ),
      endsWith: '.com.mx'
    }
  ];

  const handleChangeRegion = (selectedRegion) => {
    const newRegion = regionsData.find((r) => r.value === selectedRegion.value);
    setSelectedRegionData(newRegion);

    trackEvent(AnalysisSegment.SEGMENT_EVENTS.Log_In_Select_Region_Changed, {
      region_selected: newRegion.value
    });

    redirectToRegion(newRegion.value);
  };

  const handleOpenRedirectDialog = (country) => {
    // If the dialog has been closed on another occasion, nothing is done
    if (!country) return;

    if (redirectTimer) {
      clearTimeout(redirectTimer);
    }

    setCountdown(COUNTDOWN_IN_SECONDS);

    trackEvent(AnalysisSegment.SEGMENT_EVENTS.Log_In_Pop_Up_Region_Viewed);

    setIsRedirectDialogOpen(true);

    // Create interval to update the counter every second
    const timer = setInterval(() => {
      setCountdown((prev) => {
        // When it reaches 0, clear the interval and perform the redirect
        if (prev <= 1) {
          clearInterval(timer);
          redirectToRegion(country);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
    // Save the timer reference
    setRedirectTimer(timer);
  };

  const handleCloseRedirectDialog = () => {
    // Clear the timer when the dialog closes
    if (redirectTimer) {
      clearTimeout(redirectTimer);
      setRedirectTimer(null);
    }
    setCountdown(COUNTDOWN_IN_SECONDS);
    setIsRedirectDialogOpen(false);
    // We save the preference and we won't show the prompt anymore
    savePromptChecked();
  };

  // Clear the countdown timer when the component unmounts
  useEffect(() => {
    return () => {
      if (redirectTimer) {
        clearTimeout(redirectTimer);
      }
    };
  }, [redirectTimer]);

  const getUserCountry = async () => {
    let userGeoData = {};

    try {
      const userGeoDataResponse = await fetch(GEOLOCATION_SERVICE_URL, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      userGeoData = await userGeoDataResponse.json();

      consoleLog('🌍 userGeoDataResponse', userGeoData);
    } catch (error) {
      console.error('🌍 getUserCountry error-- ', error);
    }

    return userGeoData?.country?.toLowerCase();
  };

  const getRegionFromCountry = (countryCode) => {
    const countryToRegionMap = {
      es: 'eu',
      ar: 'sa'
    };
    return countryToRegionMap[countryCode] || countryCode;
  };

  const handleTestEnvironment = () => {
    const testRegionData = {
      value: 'eu',
      text: 'testing environment',
      label: (
        <RegionSelectItem label={'testing environment'} icon={iconFlagEu} />
      ),
      endsWith: '.com'
    };
    setSelectedRegionData(testRegionData);
  };

  const setInitialRegionOption = async () => {
    const domain = window.location.hostname;

    // handle local, dev and qa environments
    if (domain.includes('localhost') || domain.includes('oneclick')) {
      handleTestEnvironment();
      return;
    }

    // Get user's country and set related state
    const currentCountryResult = await getUserCountry();
    setCurrentCountryCode(currentCountryResult);

    const currentRegion = getRegionFromCountry(currentCountryResult);
    setCurrentCountry(currentRegion);

    // Select an initial option based on the domain
    let regionMatches = [];
    let currentRegionOption = {};

    // Find regions whose 'endsWith' property matches the end of the domain
    regionMatches = regionsData?.filter((region) =>
      domain.endsWith(region.endsWith)
    );

    if (!regionMatches || !regionMatches?.length) return;

    // Find a region match based on the current country code.
    // If a match is found, set the selected option.
    // If not, check if the region exists and open a redirect dialog.
    currentRegionOption = regionMatches.find(
      (region) => region.value === currentRegion
    );

    if (currentRegionOption) {
      setSelectedRegionData(currentRegionOption);
      return;
    }

    const regionExists = regionsData.some(
      (region) => region.value === currentRegion
    );

    if (regionExists) {
      const isPromptChecked = getPromptChecked();

      if (!isPromptChecked) {
        handleOpenRedirectDialog(currentRegion);
      }

      return;
    }

    setSelectedRegionData(null);
  };

  const handleConfirmRedirect = () => {
    trackEvent(AnalysisSegment.SEGMENT_EVENTS.Log_In_Pop_Up_Region_Confirmed, {
      region_selected: currentCountry
    });
    redirectToRegion(currentCountry);
  };

  useEffect(() => {
    setInitialRegionOption();
  }, []);

  return (
    <div className='region-select__container'>
      <div
        className={`region-select__accordion ${
          isAccordionOpen ? 'region-select__accordion--open' : ''
        }`}
      >
        <div className='region-select__accordion-header'>
          <div className='region-select__accordion-header-title'>
            <TDTypography variant='body-m'>
              {t('region_select_accordion_title')}
            </TDTypography>
            <TDTypography
              variant='body-m'
              className={`region-select__accordion-header-title-region ${
                isAccordionOpen
                  ? 'region-select__accordion-header-title-region-open'
                  : ''
              }`}
              onClick={() => setIsAccordionOpen(true)}
            >
              {selectedRegionData?.text || t('region_select_option_eu')}
            </TDTypography>
          </div>
          <div
            className='region-select__accordion-header-icon'
            onClick={() => setIsAccordionOpen(!isAccordionOpen)}
          >
            {isAccordionOpen ? <XCircleIcon /> : <PlusCircleIcon />}
          </div>
        </div>
        {isAccordionOpen && (
          <div className='region-select__select'>
            <TDSelect
              options={regionsData}
              selectedOption={selectedRegionData}
              onChange={handleChangeRegion}
              placeholder={t('region_select_placeholder')}
            />
          </div>
        )}
      </div>

      <TDDialog
        isOpen={isRedirectDialogOpen}
        footer={
          <div className='region-select__dialog-footer'>
            {t('region_select_dialog_count', { seconds: countdown })}
          </div>
        }
      >
        <div className='region-select__dialog'>
          <div className='region-select__dialog-title'>
            {t('region_select_dialog_title') +
              ' ' +
              translateCountry(currentCountryCode)}
          </div>
          <div className='region-select__dialog-info'>
            {t('region_select_dialog_info')}
          </div>
          <div className='region-select__dialog-actions'>
            <TDButton onClick={handleCloseRedirectDialog} variant='transparent'>
              {t('region_select_dialog_cancel')}
            </TDButton>
            <TDButton
              color='primary'
              variant='outline'
              onClick={handleConfirmRedirect}
            >
              {t('region_select_dialog_confirm')}
            </TDButton>
          </div>
        </div>
      </TDDialog>
    </div>
  );
};

export default RegionSelect;
