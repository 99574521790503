import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BBTooltip from '../../atoms/tooltip/BBTooltip';

const defaultValue = { label: '', isEmpty: false, name: '' };

const CustomizedAxisTick = (props) => {
  const { x, y, index, data } = props;
  const [position, setPosition] = useState(0);
  const [maxWidthOfXAxisLabel, setMaxWidthOfXAxisLabel] = useState(0);
  const { label, isEmpty, name, status } = data?.[index] || defaultValue;
  const { t } = useTranslation();
  const updatePosition = useCallback(() => {
    const selector = `.custom-label-container.label-index-${index}`;
    const content = document.querySelector(selector);
    if (!content) return false;
    // max width of the x axis label is xAxis Width - 100px divided by number of labels
    const xAxisWidth = document
      .querySelector('.recharts-cartesian-axis-line')
      .getBoundingClientRect().width;
    const usableWidth = xAxisWidth - 100;
    setMaxWidthOfXAxisLabel(usableWidth / data.length);
    setPosition((-1 * content.offsetWidth) / 2);
  }, [setPosition, x, index]);

  useEffect(() => {
    updatePosition();
    window.addEventListener('resize', updatePosition, false);
    return () => window.removeEventListener('resize', updatePosition);
  }, []);

  if (label.length === 0) return null;

  return (
    <g
      className='recharts-layer recharts-cartesian-axis-tick'
      transform={`translate(${x},${y})`}
    >
      <foreignObject
        x={position - maxWidthOfXAxisLabel / 2}
        width={maxWidthOfXAxisLabel}
        height={'100%'}
        orientation='bottom'
        className='recharts-text recharts-cartesian-axis-tick-value'
        textAnchor='middle'
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          className={`custom-label-container label-index-${index} ${
            isEmpty ? 'custom-label--empty' : ''
          }`}
        >
          <BBTooltip
            text={
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    status === 'disabled'
                      ? `<span>${t(
                          'coming_soon'
                        )}</span><br/><span>${label}: ${name}</span>`
                      : `<span>${label}: ${name}</span>`
                }}
              />
            }
          >
            <div
              className={`custom-label label-index-${index} ${
                isEmpty ? 'empty' : ''
              } ${status === 'disabled' ? 'custom-label--disabled' : ''}`}
              aria-label={name}
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                maxWidth: maxWidthOfXAxisLabel - 16
              }}
            >
              {label}
            </div>
          </BBTooltip>
        </div>
      </foreignObject>
    </g>
  );
};

export default CustomizedAxisTick;
