import {
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions
} from '@headlessui/react';
import clsx from 'clsx';
import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg';
import { ReactComponent as ChevronUpDownIcon } from '../../assets/icons/chevron-down.svg';

import '../TDButton/TDButton.scss';
import './TDSelect.scss';

const TDSelect = ({
  label,
  options = [],
  selectedOption,
  onChange,
  placeholder,
  optionsTitle,
  fullWidth,
  disabledItems,
  hasError,
  errorText
}) => {
  const handleChange = (value) => {
    if (onChange) onChange(value);
  };

  const isDisabled = (option) => {
    if (!disabledItems || disabledItems.length === 0) return false;
    return disabledItems.some((item) => item.value === option.value);
  };

  return (
    <Listbox value={selectedOption || {}} onChange={handleChange}>
      {label && <Label className='td-select__label'>{label}</Label>}
      <div
        className={clsx('td-select__container', {
          'full-width': fullWidth
        })}
      >
        <ListboxButton
          className={clsx('td-select__button', {
            'td-select__button--error': hasError
          })}
        >
          <span className='td-select__text td-select__text--muted'>
            {selectedOption ? selectedOption.label : placeholder}
          </span>
          <span className='td-select__icon-container'>
            <ChevronUpDownIcon aria-hidden='true' className='td-select__icon' />
          </span>
        </ListboxButton>
        <ListboxOptions className='td-select__options'>
          {optionsTitle && (
            <div className='td-select__options-title'>{optionsTitle}</div>
          )}
          {options.map((option) => (
            <ListboxOption
              key={option.value}
              value={option}
              disabled={isDisabled(option)}
              className={clsx('td-select__option', {
                'td-select__option--selected':
                  selectedOption?.value === option.value,
                'td-select__option--disabled': isDisabled(option) && !hasError
              })}
            >
              <span
                className={clsx('td-select__option-text', {
                  'td-select__option-text--selected':
                    selectedOption?.value === option.value
                })}
              >
                {option.label}
              </span>
              {selectedOption?.value === option.value && (
                <span className='td-select__check-icon'>
                  <CheckIcon aria-hidden='true' />
                </span>
              )}
            </ListboxOption>
          ))}
        </ListboxOptions>
      </div>
      {hasError && <div className='td-select__error-text'>{errorText}</div>}
    </Listbox>
  );
};

export default TDSelect;
