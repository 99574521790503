import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Field,
  Label
} from '@headlessui/react';
import { CircularProgress } from '@mui/material';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { SearchIcon } from '../../assets/icons';
import { useDebounce } from '../../utils/hooks/useDebounce';
import './TDAutocomplete.scss';

const TDAutocomplete = ({
  label,
  placeholder,
  isLoading = true,
  options = [],
  onQuery = () => {},
  onChange = () => {},
  onClose = () => {},
  value = null,
  hasError,
  errorText
}) => {
  const [query, setQuery] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);
  const debouncedQuery = useDebounce(query, 500);

  //   debounce call to onChange
  useEffect(() => {
    onQuery(debouncedQuery);
  }, [debouncedQuery]);

  useEffect(() => {
    onChange(selectedOption);
  }, [selectedOption]);

  return (
    <Field className='td-autocomplete'>
      {label && <Label className='td-autocomplete__label'>{label}</Label>}
      <Combobox
        value={selectedOption}
        onChange={(option) => {
          const _selectedOption = options.find((opt) => opt.value === option);
          setSelectedOption(_selectedOption);
        }}
        onClose={onClose}
      >
        <div
          className={clsx('td-autocomplete__input-wrapper', {
            'td-autocomplete__input-wrapper--error': hasError
          })}
        >
          <ComboboxInput
            displayValue={(item) => item?.label || ''}
            onChange={(event) => setQuery(event.target.value)}
            className='td-autocomplete__input'
            placeholder={placeholder}
            autoComplete='off'
          />
          <div className='td-autocomplete__icon'>
            <SearchIcon />
          </div>
        </div>
        <ComboboxOptions
          anchor='bottom'
          className='w-[var(--input-width)] border empty:invisible td-autocomplete__options'
        >
          {isLoading && (
            <ComboboxOption>
              <div className='td-autocomplete__option'>
                <div className='td-autocomplete__option--loading'>
                  <CircularProgress />
                </div>
              </div>
            </ComboboxOption>
          )}
          {!isLoading && options.length === 0 && !query && (
            <ComboboxOption>
              <div className='td-autocomplete__option'>
                <div className='td-autocomplete__option--empty'>no results</div>
              </div>
            </ComboboxOption>
          )}
          {!isLoading &&
            options.map((option) => (
              <ComboboxOption
                key={option.value}
                value={option.value}
                className={clsx('td-autocomplete__option', {
                  'td-autocomplete__option--selected':
                    value?.value === option.value
                })}
              >
                {option.label}
              </ComboboxOption>
            ))}
        </ComboboxOptions>
      </Combobox>

      {hasError && (
        <div className='td-autocomplete__error-text'>{errorText}</div>
      )}
    </Field>
  );
};

export default TDAutocomplete;
