import { useTranslation } from 'react-i18next';
import { TDButton, TDSelect } from '../../../../atoms/TDUILib';

import { useEffect, useState } from 'react';
import TDAutocomplete from '../../../../atoms/TDAutocomplete/TDAutocomplete';
import { AnalysisSegment } from '../../../../utils/analysis';
import { searchForSchools } from '../../../../utils/crud/auth';
import { fetchWithoutToken } from '../../../../utils/helpers/fetcher';
import useAnalytics from '../../../../utils/hooks/useAnalytics';
import useRegisterPage from '../useRegisterPage';
import './RegisterFormUserInfoCollection.scss';

const RegisterFormUserInfoCollection = () => {
  const { t } = useTranslation();
  const { trackRawEvent } = useAnalytics();

  const [role, setRole] = useState('');
  const [educationLevels, setEducationLevels] = useState([]);
  const [educationYears, setEducationYears] = useState([]);
  const [schools, setSchools] = useState([]);
  const { createUserAndLogin, isRegisteringUser } = useRegisterPage();

  const [school, setSchool] = useState('');
  const [educationLevel, setEducationLevel] = useState('');
  const [educationYear, setEducationYear] = useState('');

  const [isLoadingSchools, setIsLoadingSchools] = useState(false);

  const [errors, setErrors] = useState({
    school: '',
    educationLevel: '',
    educationYear: ''
  });

  const ROLES = [
    {
      value: 'administrator',
      label: t('register_form_user_info_collection_role_administrator')
    },
    {
      value: 'coordinator',
      label: t('register_form_user_info_collection_role_coordinator')
    },
    {
      value: 'headOfDepartment',
      label: t('register_form_user_info_collection_role_headOfDepartment')
    },
    {
      value: 'principal',
      label: t('register_form_user_info_collection_role_principal')
    },
    {
      value: 'teacher',
      label: t('register_form_user_info_collection_role_teacher')
    }
  ];

  const onSave = () => {
    if (educationLevel && educationYear && school) {
      setErrors({});
      createUserAndLogin({
        responsibility: role.value,
        schoolGuid: school.value,
        schoolName: school.label,
        educationYearGuid: educationYear.value,
        educationLevelGuid: educationLevel.value,
        educationLevelName: educationLevel.label,
        educationYearName: educationYear.label
      });

      return;
    }

    setErrors({
      school: Boolean(!school),
      educationLevel: Boolean(!educationLevel),
      educationYear: Boolean(!educationYear)
    });
  };

  const parseSchools = (schools) => {
    return schools.map((school) => ({
      value: school.guid,
      label:
        `${school.name}` +
        `${school.zipcode ? `, (${school.zipcode})` : ''}` +
        `${school.address ? `, ${school.address}` : ''}` +
        `${school.city_name ? ` (${school.city_name})` : ''}`
    }));
  };

  const getSchools = async (value) => {
    if (!value) return;

    setIsLoadingSchools(true);
    const schools = await searchForSchools({ search: value, region: 'europe' });
    const parsedSchools = parseSchools(schools?.data?.schools);
    setSchools(parsedSchools);
    setIsLoadingSchools(false);
  };

  const getEducationLevelsAndYears = async () => {
    const licenses = await fetchWithoutToken({
      path: 'front/sign-up/licenses'
    });

    const educationLevelsAndYears = licenses.data
      .map((license) => {
        return license.educationLevels.map((level) => ({
          value: level.guid,
          label: level.name,
          educationYears: level.educationYears.map((year) => ({
            value: year.guid,
            label: year.name,
            educationLevelGuid: level.guid
          }))
        }));
      })
      .flat();

    const allEducationYears = educationLevelsAndYears
      .map((level) => level.educationYears)
      .flat();

    setEducationLevels(educationLevelsAndYears);
    setEducationYears(allEducationYears);
  };

  useEffect(() => {
    trackRawEvent(AnalysisSegment.SEGMENT_EVENTS.Sign_Up_Profile_Form_Viewed);
    getEducationLevelsAndYears();
  }, []);

  return (
    <div className='register-form-user-info-collection__container'>
      <div className='register-form-user-info-collection__input-container'>
        <div className='register-form-user-info-collection__input'>
          <TDSelect
            fullWidth
            label={t('register_form_user_info_collection_role_label')}
            placeholder={t(
              'register_form_user_info_collection_role_placeholder'
            )}
            onChange={(value) => {
              setRole(value);
            }}
            selectedOption={role}
            options={ROLES}
          />
        </div>
        <div className='register-form-user-info-collection__input'>
          <div>
            <TDAutocomplete
              fullWidth
              label={t('register_form_user_info_collection_school_label')}
              placeholder={t(
                'register_form_user_info_collection_school_placeholder'
              )}
              onChange={setSchool}
              onQuery={getSchools}
              onClose={() => {
                setSchools([]);
              }}
              isLoading={isLoadingSchools}
              options={schools}
              hasError={errors.school}
              errorText={
                errors.school
                  ? t('register_form_user_info_collection_school_error')
                  : ''
              }
            />
          </div>
        </div>
        <div className='register-form-user-info-collection__input-group'>
          <div className='register-form-user-info-collection__input'>
            <TDSelect
              fullWidth
              label={t(
                'register_form_user_info_collection_education_level_label'
              )}
              placeholder={t(
                'register_form_user_info_collection_education_level_placeholder'
              )}
              onChange={(value) => {
                setEducationLevel(value);
                setEducationYear('');
              }}
              selectedOption={educationLevel}
              options={educationLevels}
              hasError={errors.educationLevel}
              errorText={
                errors.educationLevel
                  ? t(
                      'register_form_user_info_collection_education_level_error'
                    )
                  : ''
              }
            />
          </div>
          <div className='register-form-user-info-collection__input'>
            <TDSelect
              fullWidth
              label={t(
                'register_form_user_info_collection_education_year_label'
              )}
              placeholder={t(
                'register_form_user_info_collection_education_year_placeholder'
              )}
              onChange={(value) => {
                setEducationYear(value);
              }}
              selectedOption={educationYear}
              options={educationYears.filter(
                (year) => year.educationLevelGuid === educationLevel.value
              )}
              hasError={errors.educationYear}
              errorText={
                errors.educationYear
                  ? t('register_form_user_info_collection_education_year_error')
                  : ''
              }
            />
          </div>
        </div>
      </div>
      <div className='register-form-user-info-collection__action-container'>
        <TDButton
          fullWidth
          onClick={onSave}
          disabled={isRegisteringUser}
          className='register-form-user-info-collection__action-button'
          data-testid='register-form-user-info-collection-save-button'
        >
          {isRegisteringUser
            ? t('register_form_user_info_collection_saving_button')
            : t('register_form_user_info_collection_save_button')}
        </TDButton>
      </div>
    </div>
  );
};

export default RegisterFormUserInfoCollection;
