import { useEffect, useState } from 'react';
import { AnalysisSegment } from '../../utils/analysis';
import useAnalytics from '../../utils/hooks/useAnalytics';

export default function useTooltip({ pageName }) {
  const [isTooltipOpen, setIsTooltipOpen] = useState(true);
  const { trackEvent } = useAnalytics();
  const entryName = 'bb_page_tooltip';
  const pageIdentifier = pageName.toLowerCase().replace(/ /g, '_');

  const loadConfig = () => {
    const config = window.localStorage.getItem(entryName);
    return config !== null ? JSON.parse(config) : {};
  };

  const saveConfig = (config, value) => {
    const entryValue = {
      ...config,
      [pageIdentifier]: value
    };

    window.localStorage.setItem(entryName, JSON.stringify(entryValue));
    return value;
  };

  const getConfig = () => {
    const config = loadConfig();
    console.log(config);

    return config[pageIdentifier] !== undefined
      ? config[pageIdentifier]
      : saveConfig(config, true);
  };

  useEffect(() => {
    setIsTooltipOpen(getConfig());
  }, [pageName]);

  const onToggleTooltip = () => {
    // track event only if the tooltip is being toggled by the user.
    trackEvent(AnalysisSegment.SEGMENT_EVENTS.Info_Panel_Clicked, {
      page: pageName,
      status: isTooltipOpen ? 'closed' : 'opened'
    });

    saveConfig(loadConfig(), !isTooltipOpen);
    setIsTooltipOpen(!isTooltipOpen);
  };

  return { isTooltipOpen, onToggleTooltip };
}
