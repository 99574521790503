import { Alert, AlertTitle, CircularProgress } from '@mui/material';
import { useState } from 'react';

import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as BBMathLogo } from '../../../assets/bb-math-logo.svg';
import { TDButton, TDInputText, TDTypography } from '../../../atoms/TDUILib';
import RegionSelect from '../../../components/RegionSelect/RegionSelect';
import { isLayersTenant } from '../../../utils/helpers/whichTenant';
import './LoginForm.scss';
import LoginWithLayers from './components/LoginWithLayers';

const LoginForm = ({
  error,
  setError,
  isLoading,
  onLoginWithGoogle,
  onLoginWithEmailAndPassword
}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [credentials, setCredentials] = useState({
    email: '',
    password: ''
  });

  const loginWithGoogle = useGoogleLogin({
    onSuccess: (tokenResponse) =>
      onLoginWithGoogle({ credential: tokenResponse.access_token })
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    onLoginWithEmailAndPassword(credentials);
  };

  if (isLayersTenant()) {
    return (
      <div className='login-form__container'>
        <div className='login-form__header'>
          <TDTypography variant='h2' fontWeight='semibold'>
            {t('login_to_dashboard')}
          </TDTypography>
        </div>
        {error && (
          <div className='login-form__input'>
            <Alert severity='error'>
              <AlertTitle>{t(error)}</AlertTitle>
            </Alert>
          </div>
        )}
        <div className='login-form__body'>
          <div className='login-form__layers'>
            {isLoading ? <CircularProgress /> : <LoginWithLayers />}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='login-form__container'>
      <div className='login-form__header' data-testid='login-heading'>
        <div className='login-form__header-logo'>
          <BBMathLogo />
        </div>
        <TDTypography variant='body-xl' fontWeight='semibold'>
          {t('login_to_dashboard')}
        </TDTypography>
      </div>
      <div className='login-form__body' onSubmit={handleSubmit}>
        <div className='login-form__form'>
          <div className='login-form__region-select'>
            <RegionSelect label={t('region_select_label')} />
          </div>
          <div className='login-form__input'>
            <TDInputText
              fullWidth
              disabled={isLoading}
              type='text'
              label={t('email_or_username')}
              placeholder={t('email_placeholder')}
              value={credentials.email}
              testId='email-input'
              onChange={({ target: { value } }) => {
                setCredentials({
                  ...credentials,
                  email: value
                });

                setError('');
              }}
              hasError={error}
              errorText={t(error)}
            />
          </div>
          <div className='login-form__input'>
            <TDInputText
              fullWidth
              disabled={isLoading}
              type='password'
              label={t('password')}
              placeholder={t('password_placeholder')}
              value={credentials.password}
              testId='password-input'
              onChange={({ target: { value } }) => {
                setCredentials({
                  ...credentials,
                  password: value
                });

                setError('');
              }}
              hasError={error}
              errorText={t(error)}
            />
          </div>
          <div className='login-form__forgot-password'>
            <TDButton
              size='sm'
              color='primary'
              variant='transparent'
              onClick={() => console.log('forgot password')}
              loading={isLoading}
              text={t('forgot_password')}
              fullWidth
            >
              {t('forgot_password')}
            </TDButton>
          </div>
        </div>
        <div className='login-form__actions'>
          <div className='login-form__submit'>
            <TDButton
              color='primary'
              variant='solid'
              fullWidth
              onClick={() => onLoginWithEmailAndPassword(credentials)}
              loading={isLoading}
              data-testid='login-button'
            >
              {isLoading ? t('logging in') : t('login')}
            </TDButton>
          </div>
          <div className='login-form__or'>
            <TDTypography
              variant='body-m'
              fontWeight='bold'
              className='login-form__or-text'
            >
              {t('or')}
            </TDTypography>
          </div>
          <div className='login-form__google-login'>
            <GoogleLogin
              shape='pill'
              logo_alignment='center'
              size='large'
              width={352}
              text='continue_with'
              locale={i18n.resolvedLanguage}
              onSuccess={onLoginWithGoogle}
              onError={(error) => {
                console.error(error);
              }}
            />
          </div>

          <div className='login-form__register-section'>
            <TDTypography
              variant='body-m'
              fontWeight='semibold'
              className='login-form__register-section-text'
            >
              {t('login_form_register_text')}
            </TDTypography>
            <TDButton
              variant='transparent'
              color='primary'
              className='login-form__register-button'
              fullWidth
              onClick={() => navigate('/register')}
            >
              {t('login_form_register_button')}
            </TDButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
