import './CellComingSoon.scss';

const CellComingSoon = ({ text }) => {
  return (
    <div className='cell__container cell--coming-soon'>
      <div className='cell__coming-soon'>{text}</div>
    </div>
  );
};

export default CellComingSoon;
