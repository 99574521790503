import { createContext, useState } from 'react';

const REGISTRATION_STEPS = {
  accountInfoCollection: 'account-info-collection',
  verification: 'verification',
  userInfoCollection: 'user-info-collection'
};

export const RegistrationContext = createContext({
  currentStep: REGISTRATION_STEPS.accountInfoCollection,
  setCurrentStep: () => {},
  REGISTRATION_STEPS,
  userCredentials: {
    email: '',
    password: '',
    firstName: '',
    lastName: ''
  }
});

export const RegistrationProvider = ({ children }) => {
  const [currentStep, setCurrentStep] = useState(
    REGISTRATION_STEPS.accountInfoCollection
  );
  const [userCredentials, setUserCredentials] = useState({
    email: '',
    password: '',
    firstName: '',
    lastName: ''
  });

  return (
    <RegistrationContext.Provider
      value={{
        currentStep,
        setCurrentStep,
        REGISTRATION_STEPS,
        userCredentials,
        setUserCredentials
      }}
    >
      {children}
    </RegistrationContext.Provider>
  );
};
