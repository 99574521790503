import { useEffect, useState } from 'react';
import { AnalysisSegment } from '../../../utils/analysis';
import { useCourse } from '../../../utils/contexts/CourseContext';
import { getXRay } from '../../../utils/crud/xray';
import secureRandom from '../../../utils/helpers/secureRandom';
import useAnalytics from '../../../utils/hooks/useAnalytics';

const colors = [
  'rgb(29, 186, 228)',
  'rgb(204, 138, 34)',
  'rgb(88, 134, 160)',
  'rgb(0, 70, 67)',
  'rgb(206, 15, 72)',
  'rgb(56, 176, 124)',
  'rgb(145, 173, 36)',
  'rgb(240, 132, 132)',
  'rgb(120, 51, 139)',
  'rgb(119, 111, 178)'
];

const useXRay = () => {
  const [xRay, setXRay] = useState([]);
  const [effortRange, setEffortRange] = useState({ min: 0, max: 0 });
  const [progressRange, setProgressRange] = useState({ min: 0, max: 0 });

  const { selectedCourse } = useCourse();
  const { trackEvent } = useAnalytics();

  const fetchXRay = async () => {
    const xRayResponse = await getXRay({ courseGuid: selectedCourse?.guid });

    setXRay(
      xRayResponse.details.map((detail) => ({
        y:
          (detail.progress > xRayResponse.progress.maximum
            ? xRayResponse.progress.maximum
            : detail.progress) / xRayResponse.progress.maximum,
        x:
          (detail.effort > xRayResponse.effort.maximum
            ? xRayResponse.effort.maximum
            : detail.effort) / xRayResponse.effort.maximum,
        student: {
          ...detail.student,
          effort: detail.effort,
          progress: detail.progress,
          color: colors[Math.floor(secureRandom() * colors.length)]
        }
      }))
    );

    setEffortRange({
      min: xRayResponse.effort.minimum - xRayResponse.effort.maximum * 0.1,
      max: xRayResponse.effort.maximum * 1.1,
      median: xRayResponse.effort.maximum / 2
    });

    setProgressRange({
      min: xRayResponse.progress.minimum - xRayResponse.progress.maximum * 0.1,
      max: xRayResponse.progress.maximum * 1.1,
      median: xRayResponse.progress.maximum / 2
    });
  };

  useEffect(() => {
    fetchXRay();
    if (!selectedCourse) return;

    trackEvent(AnalysisSegment.SEGMENT_EVENTS.XRay_Page_Viewed);
  }, [selectedCourse]);

  const onClickStudent = (student) => {
    const effort = (100 * student[0]?.effort) / effortRange.max;
    const progress = (100 * student[0]?.progress) / progressRange.max;

    trackEvent(AnalysisSegment.SEGMENT_EVENTS.XRay_Student_Detail_Viewed, {
      student_id: student[0]?.person_guid,
      effort:
        effort && typeof effort === 'number' ? parseInt(effort) + '%' : '',
      progress:
        progress && typeof progress === 'number' ? parseInt(progress) + '%' : ''
    });
  };

  const onClickStudentDetail = (student_id) => {
    trackEvent(AnalysisSegment.SEGMENT_EVENTS.Student_Profile_Button_Clicked, {
      student_id: student_id,
      source: 'xRay'
    });
  };

  return {
    xRay,
    effortRange,
    progressRange,
    onClickStudent,
    onClickStudentDetail
  };
};

export default useXRay;
