import { ThemeProvider } from '@emotion/react';
import { AddCircleOutline } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import {
  Button,
  Container,
  Grid,
  Skeleton,
  Stack,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TDHeaderButton } from '../../../../atoms/TDUILib';
import PageTitleWithTooltip from '../../../../components/pageTitleWithTooltip/PageTitleWithTooltip';
import QuizCard from '../../../../components/QuizCard/QuizCard';
import PageWithHeader from '../../../../layouts/pageWithHeader/PageWithHeader';
import { AnalysisSegment } from '../../../../utils/analysis';
import { useCourse } from '../../../../utils/contexts/CourseContext';
import generateAssetURL from '../../../../utils/helpers/generateAssetURL';
import useAuth from '../../../../utils/hooks/useAuth';
import QuizBlockedByActiveSessionDialog from '../components/QuizBlockedByActiveSessionDialog';
import QuizConfirmDeleteDialog from '../components/QuizConfirmDeleteDialog';
import QuizConfirmPlayDialog from '../components/QuizConfirmPlayDialog';
import QuizEditNameDialog from '../components/QuizEditNameDialog';
import quizTheme from '../components/QuizTheme';
import ListQuizzesTooltip from './listQuizzesTooltip/ListQuizzesTooltip';
import useListQuizzes from './useListQuizzes';

const ListQuizzes = () => {
  const { t } = useTranslation();
  const { selectedCourse } = useCourse();

  const {
    quizzes,
    isLoading,
    selectedQuiz,
    isEditingName,
    isDeletingQuiz,
    selectedQuizGuid,
    isPlayDialogOpen,
    isDeleteDialogOpen,
    isEditNameDialogOpen,
    isActiveSessionBlockDialogOpen,
    onDeleteQuiz,
    onEditName,
    onPlayQuiz,
    onConfirmPlayQuiz,
    onCancelPlayQuiz,
    onConfirmEditName,
    onCancelEditName,
    onConfirmDeleteQuiz,
    onCancelDeleteQuiz,
    onClickOnCreateQuiz,
    onCloseActiveSessionBlockDialog
  } = useListQuizzes();

  const { schoolAssetsPath } = useAuth();

  const noQuizzesImage = generateAssetURL({
    asset: 'avatar_love.png',
    schoolAssetsPath
  });

  if (isLoading) {
    return (
      <ThemeProvider theme={quizTheme}>
        <Container maxWidth={false}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Stack
                direction='row'
                alignItems='center'
                justifyContent='space-between'
              >
                <Skeleton variant='text' width={200} height={60} />
                <Skeleton variant='text' width={200} height={60} />
              </Stack>
            </Grid>
            {new Array(6).fill(0).map((_, index) => (
              <Grid item sm={6} lg={4} xs={12} key={index}>
                <Skeleton variant='rectangular' height={300} />
              </Grid>
            ))}
          </Grid>
        </Container>
      </ThemeProvider>
    );
  }

  if (!quizzes || !quizzes.length) {
    return (
      <ThemeProvider theme={quizTheme}>
        <Container maxWidth={false}>
          <Grid
            container
            justifyContent='center'
            alignItems='center'
            columns={1}
          >
            <Grid item xs={1}>
              <PageWithHeader
                left={<PageTitleWithTooltip title={t('Quiz')} />}
                right={
                  <TDHeaderButton
                    border
                    icon={<AddCircleOutline />}
                    onClick={onClickOnCreateQuiz}
                  >
                    {t('New quiz')}
                  </TDHeaderButton>
                }
                tooltip={<ListQuizzesTooltip />}
                pageName={AnalysisSegment.PAGE_NAMES.Quizzes_Page}
              >
                <Grid item container mt='60px' justifyContent='center'>
                  <img src={noQuizzesImage} alt={t('no_quizzes')} width={200} />
                </Grid>

                <Grid
                  item
                  container
                  mt='24px'
                  mb='48px'
                  justifyContent='center'
                >
                  <Typography variant='h3' textAlign='center'>
                    {t('no_quizzes')}
                  </Typography>
                </Grid>

                <Grid item container justifyContent='center'>
                  <Button
                    variant='contained'
                    onClick={onClickOnCreateQuiz}
                    startIcon={<AddIcon />}
                    disableElevation
                    size='lg'
                  >
                    {t('New quiz')}
                  </Button>
                </Grid>
              </PageWithHeader>
            </Grid>
          </Grid>
        </Container>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={quizTheme}>
      <div>
        <Container maxWidth={false}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <PageWithHeader
                left={<PageTitleWithTooltip title={t('Quiz')} data-testid='quiz-page-number-of-quizzes' />}
                right={
                  <TDHeaderButton
                    border
                    icon={<AddCircleOutline />}
                    onClick={onClickOnCreateQuiz}
                  >
                    {t('New quiz')}
                  </TDHeaderButton>
                }
                tooltip={<ListQuizzesTooltip />}
                pageName={AnalysisSegment.PAGE_NAMES.Quizzes_Page}
              >
                <Grid container columns={6} spacing={3}>
                  <Grid item xs={6}>
                    <Typography
                      variant='subtitle1'
                      data-testid='quiz-page-number-of-quizzes'
                    >
                      {`${quizzes.length} ${t('Quizzes_created', {
                        count: quizzes.length
                      })}`}
                    </Typography>
                  </Grid>

                  {quizzes.map((quiz) => (
                    <Grid item sm={6} md={3} lg={2} key={quiz.guid}>
                      <QuizCard
                        guid={quiz?.guid}
                        name={quiz?.name}
                        lang={quiz?.lang}
                        numberOfActivities={quiz?.numberOfActivities}
                        onDeleteQuiz={onDeleteQuiz}
                        isDeletingQuiz={isDeletingQuiz}
                        selectedQuizGuid={selectedQuizGuid}
                        onEditName={onEditName}
                        hasResults={quiz?.hasResults}
                        onPlayQuiz={onPlayQuiz}
                      />
                    </Grid>
                  ))}
                </Grid>
              </PageWithHeader>
            </Grid>
          </Grid>
        </Container>

        {selectedQuizGuid && (
          <QuizEditNameDialog
            isOpen={isEditNameDialogOpen}
            onCancel={onCancelEditName}
            onSave={onConfirmEditName}
            quizGuid={selectedQuizGuid}
            initialName={selectedQuiz?.name || ''}
          />
        )}

        <QuizConfirmDeleteDialog
          isOpen={isDeleteDialogOpen}
          onCancel={onCancelDeleteQuiz}
          onDelete={onConfirmDeleteQuiz}
          title={t('delete_quiz_dialog_title')}
          description={t('delete_quiz_dialog_description')}
        />

        <QuizConfirmPlayDialog
          isOpen={isPlayDialogOpen}
          onCancel={onCancelPlayQuiz}
          onPlay={onConfirmPlayQuiz}
          title={t('play_quiz_dialog_title')}
          description={t('play_quiz_dialog_description')}
        />

        <QuizBlockedByActiveSessionDialog
          isOpen={isActiveSessionBlockDialogOpen}
          onClose={onCloseActiveSessionBlockDialog}
          title={t('quiz_blocked_by_active_session_title')}
          description={t('quiz_blocked_by_active_session_description')}
        />
      </div>
    </ThemeProvider>
  );
};

export default ListQuizzes;
