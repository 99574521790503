import { Avatar } from '@mui/material';
import { useState } from 'react';
import Card from '../../atoms/card/Card';
import StudentXrayPopup from './StudentXrayPopup';

const MultipleStudentXrayPopup = ({
  students,
  progressRange,
  effortRange,
  onClickStudentDetail
}) => {
  const [studentAnchorEl, setStudentAnchorEl] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [open, setOpen] = useState(false);

  function updateStudent(event, student) {
    setSelectedStudent(student);
    setStudentAnchorEl(event.currentTarget);
    setOpen(true);
  }

  function clearStudent() {
    setSelectedStudent(null);
    setStudentAnchorEl(null);
    setOpen(false);
  }

  function handleClickStudent(event, student) {
    return student.person_guid === selectedStudent?.person_guid
      ? clearStudent()
      : updateStudent(event, student);
  }

  return (
    <Card>
      <div className='multiple-student-xray-popup'>
        {students.map((student) => (
          <div
            className='multiple-student-xray-popup__student'
            key={student.person_guid}
            onClick={(event) => {
              handleClickStudent(event, student);
            }}
          >
            <Avatar
              sizes='xs'
              sx={{
                bgcolor: student.color,
                width: 34,
                height: 34,
                fontSize: '0.85rem',
                cursor: 'pointer'
              }}
            >
              {student?.name.charAt(0)}
              {student?.lastname.charAt(0)}
            </Avatar>
          </div>
        ))}

        {open && (
          <StudentXrayPopup
            progressRange={progressRange}
            effortRange={effortRange}
            anchorEl={studentAnchorEl}
            selectedStudents={[selectedStudent]}
            onClickStudentDetail={onClickStudentDetail}
            onClose={clearStudent}
          />
        )}
      </div>
    </Card>
  );
};

export default MultipleStudentXrayPopup;
