import { useNavigate } from 'react-router-dom';
import { isDeviceSupported } from '../../../utils/helpers/device';
import useAuth from '../../../utils/hooks/useAuth';
import LoginForm from './LoginForm';

const LoginPage = ({ forceDisableAnalytics }) => {
  const {
    error,
    setError,
    onLoginWithEmailAndPassword,
    onLoginWithGoogle,
    isLoading
  } = useAuth(forceDisableAnalytics);

  const navigate = useNavigate();

  const checkSupportedDevice = (functionLogin, args) => {
    if (!isDeviceSupported()) {
      navigate('/device-error', { replace: true });
    } else {
      functionLogin(args);
    }
  };

  return (
    <LoginForm
      error={error}
      setError={setError}
      isLoading={isLoading}
      onLoginWithGoogle={(credentials) => {
        checkSupportedDevice(onLoginWithGoogle, credentials);
      }}
      onLoginWithEmailAndPassword={(credentials) => {
        checkSupportedDevice(onLoginWithEmailAndPassword, credentials);
      }}
    />
  );
};

export default LoginPage;
