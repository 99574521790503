import { useTranslation } from 'react-i18next';
import TDProgressBar from '../../../atoms/TDProgressBar/TDProgressBar';
import AuthHeader from '../components/AuthHeader/AuthHeader';
import './RegisterPage.scss';
import { RegistrationProvider } from './RegistrationContext';
import RegisterFormAccountInfoCollection from './steps/RegisterFormAccountInfoCollection';
import RegisterFormEmailVerification from './steps/RegisterFormEmailVerification';
import RegisterFormUserInfoCollection from './steps/RegisterFormUserInfoCollection';
import useRegisterPage from './useRegisterPage';

const WrappedRegisterPage = () => {
  const { t } = useTranslation();
  const { currentStep, REGISTRATION_STEPS, userCredentials } =
    useRegisterPage();

  const mapStepToProgressValue = {
    [REGISTRATION_STEPS.accountInfoCollection]: 50, // TODO: change to 33 when we have the email verification
    [REGISTRATION_STEPS.verification]: 66,
    [REGISTRATION_STEPS.userInfoCollection]: 100
  };

  const mapHeaderToStep = {
    [REGISTRATION_STEPS.accountInfoCollection]: {
      title: t('register_form_account_info_collection_title'),
      subTitle: t('register_form_account_info_collection_sub_title'),
      subTitleVariant: 'light',
      component: <RegisterFormAccountInfoCollection />
    },
    [REGISTRATION_STEPS.verification]: {
      title: t('register_form_email_verification_title'),
      subTitle: `${userCredentials.email}`,
      subTitleVariant: 'dark',
      component: <RegisterFormEmailVerification />
    },
    [REGISTRATION_STEPS.userInfoCollection]: {
      title: t('register_form_user_info_collection_title'),
      subTitle: t('register_form_user_info_collection_sub_title'),
      subTitleVariant: 'light',
      component: <RegisterFormUserInfoCollection />
    }
  };

  return (
    <div className='register-page__container'>
      <TDProgressBar
        value={mapStepToProgressValue[currentStep]}
        className='register-page__container__progress-bar'
      />
      <AuthHeader
        title={mapHeaderToStep[currentStep].title}
        subTitle={mapHeaderToStep[currentStep].subTitle}
        subTitleVariant={mapHeaderToStep[currentStep].subTitleVariant}
        className='register-page__container__header'
      />
      {mapHeaderToStep[currentStep].component}
    </div>
  );
};

const RegisterPage = () => {
  return (
    <RegistrationProvider>
      <WrappedRegisterPage />
    </RegistrationProvider>
  );
};

export default RegisterPage;
