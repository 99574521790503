import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TDButton, TDDialog, TDTypography } from '../../../../atoms/TDUILib';
import { useCourse } from '../../../../utils/contexts/CourseContext';
import generateAssetURL from '../../../../utils/helpers/generateAssetURL';
import useAuth from '../../../../utils/hooks/useAuth';
import { useLocalStorage } from '../../../../utils/hooks/useLocalStorage';
import useOnboarding from '../useOnboarding';
import './WelcomeDialog.scss';
const WelcomeDialog = () => {
  const { setItem } = useLocalStorage();
  const { isDialogOpen, setIsDialogOpen } = useOnboarding();
  const { user, schoolAssetsPath } = useAuth();
  const { t } = useTranslation();
  const { selectedCourse } = useCourse();
  useEffect(() => {
    console.log(selectedCourse);
  }, [selectedCourse]);

  const avatarIcon = generateAssetURL({
    asset: 'avatar_thumbs_up.svg',
    schoolAssetsPath: schoolAssetsPath
  });

  if (!selectedCourse) {
    return null;
  }

  return (
    <TDDialog
      isOpen={isDialogOpen}
      size='sm'
      onClose={() => {
        setItem('bb_enable_welcome_onboarding', false);
        setIsDialogOpen(false);
      }}
      className='welcome-dialog'
    >
      <TDTypography className='welcome-dialog__title'>
        {t('welcome_dialog_title', { name: user.name })}
      </TDTypography>
      <TDTypography className='welcome-dialog__subtitle'>
        {t('welcome_dialog_subtitle')}
      </TDTypography>
      <div className='welcome-dialog__image-container'>
        <img
          src={avatarIcon}
          alt='Welcome avatar'
          className='welcome-dialog__image'
        />
      </div>
      <TDTypography className='welcome-dialog__description-title'>
        {t('welcome_dialog_description_title', {
          educationYear: selectedCourse.education_year_name,
          educationLevel: selectedCourse.education_level_name
        })}
      </TDTypography>
      <TDTypography className='welcome-dialog__description'>
        {t('welcome_dialog_description')}
      </TDTypography>
      <TDButton
        variant='outline'
        color='muted'
        onClick={() => {
          setItem('bb_enable_welcome_onboarding', false);
          setIsDialogOpen(false);
        }}
      >
        {t('welcome_dialog_button')}
      </TDButton>
    </TDDialog>
  );
};

export default WelcomeDialog;
