import './TDButton.scss';

const TDButton = ({
  text,
  onClick,
  disabled,
  size = 'default',
  variant = 'solid', // solid, outlined, text, transparent, underlined
  radius = 'default', // default, rounded, small
  color = 'primary',
  icon,
  iconPosition = 'left',
  fullWidth = false,
  selected = false,
  className = '',
  children,
  as,
  ...props
}) => {
  const buttonClasses = [
    'td-button',
    `td-button--size-${size}`,
    `td-button--color-${color}`,
    `td-button--radius-${radius}`,
    `td-button--variant-${variant}`,
    disabled ? 'td-button--status-disabled' : '',
    selected ? 'td-button--status-selected' : '',
    fullWidth ? 'td-button--width-full' : 'td-button--width-auto',
    icon ? `td-button--icon-${iconPosition}` : '',
    className
  ]
    .filter(Boolean)
    .join(' ');

  // add support for as in case the button was a link
  const Component = as || 'button';

  return (
    <Component
      className={buttonClasses}
      {...(onClick && { onClick })}
      {...props}
    >
      {icon && <span className='td-button__icon'>{icon}</span>}
      {children}
    </Component>
  );
};

export default TDButton;
