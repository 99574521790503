import { Navigate, Outlet } from 'react-router-dom';
import useAuth from '../utils/hooks/useAuth';

const PublicRoute = () => {
  const isQaLogin = window.location.pathname?.includes('login-qa');
  const { isLoggedIn } = useAuth(isQaLogin);

  if (isLoggedIn) {
    const searchParams = new URLSearchParams(window.location.search);
    const redirectUri = searchParams.get('redirect_uri');

    if (redirectUri) {
      // make sure that redirect_uri is not the same as the current url
      // make sure that redirect_uri is not the same protocol
      if (redirectUri.includes(window.location.host)) {
        return (window.location.href = redirectUri
          .replace('http://', '')
          .replace('https://', '')
          .replace(
            window.location.host,
            `${window.location.protocol}//${window.location.host}`
          ));
      }
    }

    return <Navigate to={`/${window.location.search}`} />;
  }

  return <Outlet />;
};

export default PublicRoute;
