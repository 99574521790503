import { createColumnHelper } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CellComingSoon from '../../../../components/table/cell/cellTypes/CellComingSoon';
import CellWithLabelAndProgressBar from '../../../../components/table/cell/cellTypes/CellWithLabelAndProgressBar';
import CellWithTitleAndSubtitle from '../../../../components/table/cell/cellTypes/CellWithTitleAndSubtitle';
import CellWithTwoFontWeights from '../../../../components/table/cell/cellTypes/CellWithTwoFontWeights';
import ColumnTitleWithTooltip from '../../../../components/table/cell/cellTypes/ColumnTitleWithTooltip';
import { useCourse } from '../../../../utils/contexts/CourseContext';
import { getCourseStandardsWithStatus } from '../../../../utils/crud/content';
import { handleNaN } from '../../../../utils/helpers/handleNaN';
import { useLocalStorage } from '../../../../utils/hooks/useLocalStorage';
import { localStorageKeys } from '../../../../utils/localStorageKeys';

const useContentsStandardAnalysis = () => {
  const columnHelper = createColumnHelper();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { getItem } = useLocalStorage();
  const { t } = useTranslation();

  const { selectedCourse, availableCourses } = useCourse();

  const [isPreloading, setIsPreloading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const [columns] = useState([
    columnHelper.accessor('standardGuid', {
      header: t('Standards'),
      id: 'standardGuid',
      enableSorting: true,
      sortingFn: 'alphanumeric',
      cell: (info) => (
        <CellWithTitleAndSubtitle
          title={info.row.original.standardSkill}
          aboveTitle={info.row.original.standardGuid}
        />
      )
    }),
    columnHelper.accessor('numberOfStudentsFraction', {
      header: (
        <ColumnTitleWithTooltip
          title={t('accessed')}
          tooltip={t('accessed_tooltip')}
        />
      ),
      id: 'numberOfStudentsFraction',
      enableSorting: true,
      sortingFn: 'alphanumeric',
      cell: (info) => (
        <CellWithTwoFontWeights
          bold={info.row.original.numberOfStudentsFraction}
          regular={t(info.row.original.students + '_lowercase')}
        />
      )
    }),
    columnHelper.accessor('achievedProgress', {
      header: (
        <ColumnTitleWithTooltip
          title={t('MASTERED')}
          tooltip={t('page_tooltip.mastered_info')}
        />
      ),
      id: 'achievedProgress',
      enableSorting: true,
      sortingFn: 'cellWithLabelAndProgressBar',
      cell: (info) => (
        <CellWithLabelAndProgressBar
          label={info.row.original.achieved}
          progress={info.row.original.achievedProgress}
          color='success'
        />
      )
    }),
    columnHelper.accessor('inProgressProgress', {
      header: (
        <ColumnTitleWithTooltip
          title={t('PROGRESS')}
          tooltip={t('page_tooltip.in_progress_info')}
        />
      ),
      id: 'inProgressProgress',
      enableSorting: true,
      sortingFn: 'cellWithLabelAndProgressBar',
      cell: (info) => (
        <CellWithLabelAndProgressBar
          label={info.row.original.inProgress}
          progress={info.row.original.inProgressProgress}
          color='info'
        />
      )
    }),
    columnHelper.accessor('needsHelpProgress', {
      header: (
        <ColumnTitleWithTooltip
          title={t('HELP')}
          tooltip={t('page_tooltip.needs_help_info')}
        />
      ),
      id: 'needsHelpProgress',
      enableSorting: true,
      sortingFn: 'cellWithLabelAndProgressBar',
      cell: (info) => (
        <CellWithLabelAndProgressBar
          label={info.row.original.needsHelp}
          progress={info.row.original.needsHelpProgress}
          color='warning'
        />
      )
    }),
    columnHelper.accessor('needsEncouragementProgress', {
      header: (
        <ColumnTitleWithTooltip
          title={t('MOTIVATION')}
          tooltip={t('page_tooltip.difficulties_info')}
        />
      ),
      id: 'needsEncouragementProgress',
      enableSorting: true,
      sortingFn: 'cellWithLabelAndProgressBar',
      cell: (info) => (
        <CellWithLabelAndProgressBar
          label={info.row.original.needsEncouragement}
          progress={info.row.original.needsEncouragementProgress}
          color='danger'
        />
      )
    })
  ]);

  const getPercentage = (numerator, denominator) => {
    if (denominator === 0) return '0%';
    return `${Math.round((100 * numerator) / denominator)}%`;
  };

  const getData = async () => {
    if (isLoading) return;
    setIsLoading(true);
    let tempData = await getCourseStandardsWithStatus({
      courseGuid: selectedCourse.guid,
      ...(searchParams.get('standard')
        ? { standardGuid: searchParams.get('standard') }
        : {})
    });

    // Se obtienen los standars de los programas
    const courses = availableCourses;
    let standardsByProgram = null;
    if (!Array.isArray(courses)) {
      standardsByProgram = courses?.bb_program_standard;
    } else {
      for (const courseIndex in courses) {
        standardsByProgram = courses[courseIndex]?.bb_program_standard;
      }
    }
    let startsWithValuesStandards = [];
    if (standardsByProgram && standardsByProgram === 'BNCC') {
      startsWithValuesStandards.push('EF');
    }

    if (standardsByProgram && standardsByProgram === 'CC') {
      startsWithValuesStandards.push('CCSS');
    }

    const userLanguage = getItem(localStorageKeys.language).toUpperCase();

    const getStandardTranslation = (item) => {
      // Takes the user language, if it's not defined, takes the first one available
      const translation =
        item?.standardLanguages?.find(
          (item) => item.langCode === userLanguage
        ) || item?.standardLanguages?.[0];
      return translation;
    };

    const parsedData = tempData.standards.map((standard) => {
      const totalStudents = tempData.totalStudents;
      if (standard.status === 'draft') {
        return {
          standardGuid: standard.standardGuid,
          standardName: standard.standardName,
          standardSkill: getStandardTranslation(standard).skills,
          numberOfStudentsFraction: `${standard.students}/${totalStudents}`,
          students: 'students',
          status: 'coming_soon',
          rowType: 'custom',
          disabled: true,
          customRow: (
            <>
              <td>
                <CellWithTitleAndSubtitle
                  title={standard?.standardName}
                  aboveTitle={standard?.standardSkill}
                />
              </td>
              <td colSpan={5}>
                <CellComingSoon text={t('coming_soon')} />
              </td>
            </>
          )
        };
      } else {
        console.log(handleNaN(getPercentage(standard.mastered, totalStudents)));
        return {
          id: standard.standardGuid,
          standardGuid: standard.standardGuid,
          standardName: standard.standardName,
          standardSkill: getStandardTranslation(standard).skills,
          numberOfStudentsFraction: `${standard.students}/${totalStudents}`,
          students: 'students',
          achieved: getPercentage(standard.mastered, totalStudents),
          achievedProgress: standard.mastered / totalStudents,
          achievedColor: 'success',
          inProgress: getPercentage(standard.inProgress, totalStudents),
          inProgressProgress: standard.inProgress / totalStudents,
          inProgressColor: 'info',
          needsHelp: getPercentage(standard.needHelp, totalStudents),
          needsHelpProgress: standard.needHelp / totalStudents,
          needsHelpColor: 'warning',
          needsEncouragement: getPercentage(
            standard.needMotivation,
            totalStudents
          ),
          needsEncouragementProgress: standard.needMotivation / totalStudents,
          needsEncouragementColor: 'danger'
        };
      }
    });

    setData(parsedData);

    setIsLoading(false);
  };

  const onClickOnStandard = (standard) => {
    navigate(
      `/${selectedCourse?.guid}/content/standards/${standard.standardGuid}`
    );
  };

  useEffect(() => {
    setTimeout(() => {
      setIsPreloading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    if (isPreloading || !selectedCourse?.guid) return;
    getData();
  }, [isPreloading, selectedCourse, searchParams.get('standard')]);

  return {
    data,
    columns,
    isLoading: isLoading || isPreloading,
    onClickOnStandard
  };
};

export default useContentsStandardAnalysis;
