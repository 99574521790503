import { Autocomplete, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';
import { CourseContext } from '../../utils/contexts/CourseContext';
import { getCourseUnits } from '../../utils/crud/content';

import { getBBTeacherUserLanguage } from '../../utils/helpers/userLanguage';
import { useLocalStorage } from '../../utils/hooks/useLocalStorage';
import './TDFilter.scss';

const TDUnitsFilter = ({ defaultValue = '' }) => {
  const { t } = useTranslation();
  const { selectedCourse } = useContext(CourseContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const { setItem, getItem, removeItem } = useLocalStorage();
  const location = useLocation();

  const allUnitsValue = {
    value: 'all',
    label: t('all_units'),
    guid: 'all',
    disabled: false
  };

  const [isLoading, setIsLoading] = useState(true);
  const [units, setUnits] = useState([allUnitsValue]);
  const [selectedUnit, setSelectedUnit] = useState(allUnitsValue);

  useEffect(() => {
    setIsLoading(true);
    if (!selectedCourse?.guid) return;

    getUnits();
  }, [selectedCourse]);

  useEffect(() => {
    if (units.length === 1) return;

    if (getItem(location.pathname)) {
      setSelectedUnit(
        units.filter(
          (unit) => unit.guid === JSON.parse(getItem(location.pathname)).unit
        )[0] || units[0]
      );
    }
  }, [units]);

  const getUnits = async () => {
    const tempUnits = await getCourseUnits({
      courseGuid: selectedCourse.guid,
      lang: getBBTeacherUserLanguage()
    });

    if (defaultValue) {
      setSelectedUnit(
        tempUnits.filter((unit) => unit.guid === defaultValue)[0]
      );
    } else {
      setSelectedUnit(allUnitsValue);
    }

    setUnits([
      allUnitsValue,
      ...(Array.isArray(tempUnits)
        ? tempUnits.map((item) => ({
            ...item,
            value: item.guid,
            label: item.label,
            disabled: Boolean(item.publicationState === 'draft')
          }))
        : [])
    ]);

    setIsLoading(false);
  };

  useEffect(() => {
    if (selectedUnit === undefined || units.length === 1) return;

    if (selectedUnit?.value === 'all') {
      setSearchParams({});
      removeItem(location.pathname);
    } else {
      setItem(location.pathname, JSON.stringify({ unit: selectedUnit?.guid }));
      setSearchParams({
        unit: selectedUnit?.guid
      });
    }
  }, [selectedUnit]);

  return (
    <Autocomplete
      loading={isLoading}
      value={selectedUnit}
      onChange={(_, newValue) => {
        setSelectedUnit(newValue);
      }}
      id='units-selector'
      className='td-autocomplete-filter'
      options={units}
      getOptionDisabled={(option) => option.disabled}
      getOptionLabel={(option) => option.label}
      multiple={false}
      disableClearable
      sx={{ width: 250, padding: '0 0rem' }}
      renderInput={(params) => <TextField {...params} margin='none' />}
    />
  );
};

TDUnitsFilter.propTypes = {
  onChange: PropTypes.func,
  defaultValue: PropTypes.string
};

export default TDUnitsFilter;
