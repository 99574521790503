import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useCourse } from '../../../../utils/contexts/CourseContext';
import { getTeacherDashboardUnits } from '../../../../utils/crud/teacherDashboard';
import { getBBTeacherUserLanguage } from '../../../../utils/helpers/userLanguage';

const useClassProgressUnits = () => {
  const [isPreloading, setIsPreloading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const { selectedCourse } = useCourse();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const getData = async () => {
    setIsLoading(true);
    let response = await getTeacherDashboardUnits({
      lang: getBBTeacherUserLanguage(),
      courseGuid: selectedCourse.guid,
      ...(searchParams.get('unit')
        ? { unitGuid: searchParams.get('unit') }
        : {})
    });

    // This list could be a list of units or lessons
    const listOfAllAvailableItems = response?.filters || [];

    // This is the list of all the details per item which is a unit or lesson
    const apiResponse = response?.detail || [];
    const guidsOfItemsInApiResponse = apiResponse.map((i) => i.guid);

    // if there is a unitGuid, then we are looking at lessons
    const typeOfChart = searchParams.get('unit') ? 'lessons' : 'units';

    let listOfColumns = [];

    // if we are looking at units, we need to make sure that all the units are included in the API response of details because only the published units are included.
    // Lessons do not have this issue because the publicationstate is at the unit level.
    if (typeOfChart === 'units') {
      listOfColumns = [
        ...apiResponse,
        ...listOfAllAvailableItems
          .filter((i) => !guidsOfItemsInApiResponse.includes(i.guid))
          .map((i) => ({ ...i, index: i.unit }))
      ].sort((a, b) => a.index - b.index);
    } else {
      listOfColumns = [...apiResponse];
    }

    console.log(listOfColumns);

    const parsedData = listOfColumns.map((item, index) => {
      if (item.publicationState === 'draft') {
        return {
          status: 'disabled',
          name: item.label,
          label: item.label,
          mastered: 0,
          masteredStudents: [],
          inProgress: 0,
          inProgressStudents: [],
          needHelp: 0,
          needHelpStudents: [],
          needMotivation: 0,
          needMotivationStudents: []
        };
      }

      const masteredData = item.data.filter((i) => i.key === 'mastered')[0];
      const inProgressData = item.data.filter((i) => i.key === 'inProgress')[0];
      const needHelpData = item.data.filter((i) => i.key === 'needHelp')[0];
      const needMotivationData = item.data.filter(
        (i) => i.key === 'needMotivation'
      )[0];

      return {
        status: 'active',
        name: item.name,
        label:
          searchParams.get('unit') === null
            ? t('unit') + ' ' + (index + 1)
            : t('lesson') + ' ' + (index + 1),
        mastered: masteredData?.value,
        masteredStudents: masteredData?.students,
        inProgress: inProgressData?.value,
        inProgressStudents: inProgressData?.students,
        needHelp: needHelpData?.value,
        needHelpStudents: needHelpData?.students,
        needMotivation: needMotivationData?.value,
        needMotivationStudents: needMotivationData?.students
      };
    });
    console.log(parsedData);
    setData(parsedData);
    setIsLoading(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsPreloading(false);
    }, 500);
  }, []);

  useEffect(() => {
    if (isPreloading || !selectedCourse?.guid) return;
    getData();
  }, [isPreloading, selectedCourse, searchParams.get('unit')]);

  return {
    data,
    isLoading
  };
};

export default useClassProgressUnits;
