import { Outlet } from 'react-router-dom';

import { ReactComponent as OCLogo } from '../../assets/oc-logo.svg';

import './AuthLayout.scss';

const AuthLayout = () => {
  return (
    <div className='auth-layout'>
      <div className='auth-layout__form-container'>
        <Outlet />
      </div>
      <div className='auth-layout__oc-logo-container'>
        <OCLogo />
      </div>
    </div>
  );
};

export default AuthLayout;
