import CheckIcon from '@mui/icons-material/Check';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ViewActivity from '../viewActivity/ViewActivity';
import './DetailLoDialog.scss';
import useDetailLoDialog from './useDetailLoDialog';

const PHASES = ['identificar', 'evocar', 'aplicar'];

const PhaseCell = ({ phase, currentPhase, isMastered, text }) => {
  const isCompleted =
    PHASES.indexOf(phase) <= PHASES.indexOf(currentPhase) &&
    (PHASES.indexOf(phase) < PHASES.indexOf(currentPhase) || isMastered);

  return (
    <td
      className={`report-user-detail-header-${phase} ${
        currentPhase === phase &&
        !isMastered &&
        'report-user-detail-header-phase__current'
      }`}
    >
      {Boolean(isCompleted) && (
        <CheckIcon className='report-user-detail-header-phase__completed' />
      )}
      {text}
    </td>
  );
};

PhaseCell.propTypes = {
  phase: PropTypes.oneOf(PHASES).isRequired,
  currentPhase: PropTypes.oneOf(PHASES).isRequired,
  isMastered: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired
};

const DetailLoDialog = ({
  isOpen = false,
  onClose = () => {},
  lo,
  courseGuid,
  userGuid
}) => {
  const { t } = useTranslation();
  const { isLoading, reportDetail, displayedAttempts } = useDetailLoDialog(
    t,
    courseGuid,
    userGuid,
    lo
  );

  if (isLoading) {
    return (
      <div className='reports-container'>
        <div className='reports-container-loading'>
          <CircularProgress />
        </div>
      </div>
    );
  }

  const renderPhaseTable = () => (
    <Tooltip title={t('reports_message_phase')}>
      <table>
        <tbody>
          <tr>
            {PHASES.map((phase) => {
              const havePhase = reportDetail?.lo?.[`have_${phase}`] === 1;
              if (havePhase) {
                return (
                  <PhaseCell
                    key={phase}
                    phase={phase}
                    currentPhase={reportDetail?.lo?.phase}
                    isMastered={reportDetail?.lo?.is_mastered}
                    text={t(`reports_phase_${phase}`)}
                  />
                );
              }
              return null;
            })}
          </tr>
        </tbody>
      </table>
    </Tooltip>
  );

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth='lg'>
      <DialogTitle>
        <Stack spacing={3}>
          <Typography variant='h5' className='report-user-detail-text'>
            {`${lo.name} - `}
            <span>{`${lo?.unit?.name} - ${lo?.lesson?.name}`}</span>
          </Typography>
          <div className='report-user-detail-header'>{renderPhaseTable()}</div>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={3}>
          <div className='report-user-detail-container'>
            <ul>
              {displayedAttempts?.map((attempt) => (
                <li key={`${attempt?.start_at}-${attempt?.end_at}`}>
                  <ViewActivity attempt={attempt} />
                </li>
              ))}
            </ul>
          </div>
        </Stack>
      </DialogContent>
      <DialogActions className='add-activity-to-quiz-dialog__actions-container'>
        <div className='add-activity-to-quiz-dialog__actions'>
          <Stack direction='row' spacing={3}>
            <Button variant='text' color='info' disableRipple onClick={onClose}>
              {t('close')}
            </Button>
          </Stack>
        </div>
      </DialogActions>
    </Dialog>
  );
};

DetailLoDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};

export default DetailLoDialog;
