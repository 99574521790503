import { GoogleLogin } from '@react-oauth/google';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TDInputGroup from '../../../../atoms/TDInputGroup/TDInputGroup';
import {
  TDButton,
  TDCheckbox,
  TDInputText,
  TDTypography
} from '../../../../atoms/TDUILib';
import RegionSelect from '../../../../components/RegionSelect/RegionSelect';
import { AnalysisSegment } from '../../../../utils/analysis';
import useAnalytics from '../../../../utils/hooks/useAnalytics';
import useRegisterPage from '../useRegisterPage';
import './RegisterFormAccountInfoCollection.scss';

const TERMS_AND_CONDITIONS_URL = {
  en: 'https://oneclickes.notion.site/TERMS-AND-CONDITIONS-OF-BLUEBERRY-MATH-464a81b4546d49a8ba0e6eb4570508a4',
  es: 'https://www.notion.so/oneclickes/T-RMINOS-Y-CONDICIONES-DE-USO-DE-LA-PLATAFORMA-BLUEBERRY-MATH-0a1d7aa3c6a64cb0bdaaba1b3807585c',
  pt: 'https://www.notion.so/oneclickes/TERMOS-E-CONDI-ES-DE-USO-DA-PLATAFORMA-BLUEBERRY-MATH-27233ca99b7d49c7a5d4452df2413fbd'
};

const PRIVACY_POLICY_URL = {
  en: 'https://oneclickes.notion.site/PRIVACY-POLICY-d2fc5ac5f9bc4de6b724a2710f14518e',
  es: 'https://www.notion.so/oneclickes/POL-TICA-DE-PRIVACIDAD-67c9edb201d44a34a4ebae3d550a7521',
  pt: 'https://www.notion.so/oneclickes/POL-TICA-DE-PRIVACIDADE-eb479db932494b2eb449b086b87d0a34'
};

const RegisterFormAccountInfoCollection = () => {
  const { t, i18n } = useTranslation();
  const { trackRawEvent } = useAnalytics();
  const {
    onRegisterWithEmailAndPassword,
    onRegisterWithGoogle,
    validateRegistrationForm
  } = useRegisterPage();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [credentials, setCredentials] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: ''
  });
  const [isTermsAndConditionsChecked, setIsTermsAndConditionsChecked] =
    useState(false);
  const [errorMessages, setErrorMessages] = useState({});

  const handleRegister = async () => {
    setIsLoading(true);

    const { status, errors, errorMessages } = await validateRegistrationForm({
      ...credentials,
      termsAndConditions: isTermsAndConditionsChecked
    });

    if (status) {
      setErrors({});
      setErrorMessages({});
      await onRegisterWithEmailAndPassword(credentials);
    } else {
      setErrors(errors);
      setErrorMessages(errorMessages);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    trackRawEvent(AnalysisSegment.SEGMENT_EVENTS.Sign_Up_Form_Viewed);
  }, []);

  return (
    <div className='registration-form-account-info-collection__body'>
      <div className='registration-form-account-info-collection__form-container'>
        <div className='registration-form-account-info-collection__form'>
          <div className='registration-form-account-info-collection__region-select'>
            <RegionSelect label={t('region_select_label')} />
          </div>
          <TDInputGroup
            label={t('register_form_account_info_collection_name_label')}
            hasError={errors.firstName || errors.lastName}
            errorText={errorMessages.name}
          >
            <TDInputText
              fullWidth
              disabled={isLoading}
              type='text'
              placeholder={t(
                'register_form_account_info_collection_name_placeholder'
              )}
              value={credentials.firstName}
              inputProps={{ 'data-testid': 'name-input' }}
              onChange={({ target: { value } }) => {
                setCredentials({
                  ...credentials,
                  firstName: value
                });
              }}
            />
            <TDInputText
              fullWidth
              disabled={isLoading}
              type='text'
              placeholder={t(
                'register_form_account_info_collection_last_name_placeholder'
              )}
              value={credentials.lastName}
              inputProps={{ 'data-testid': 'last-name-input' }}
              onChange={({ target: { value } }) => {
                setCredentials({
                  ...credentials,
                  lastName: value
                });
              }}
            />
          </TDInputGroup>
          <div className='registration-form-account-info-collection__input'>
            <TDInputText
              fullWidth
              disabled={isLoading}
              type='email'
              label={t('register_form_account_info_collection_email_label')}
              placeholder={errorMessages.email}
              value={credentials.email}
              inputProps={{ 'data-testid': 'email-input', autoComplete: 'off' }}
              onChange={({ target: { value } }) => {
                setCredentials({
                  ...credentials,
                  email: value
                });
              }}
              hasError={errors.email}
              errorText={errorMessages.email}
            />
          </div>
          <div className='registration-form-account-info-collection__input'>
            <TDInputText
              fullWidth
              disabled={isLoading}
              type='password'
              label={t('register_form_account_info_collection_password_label')}
              placeholder={t(
                'register_form_account_info_collection_password_placeholder'
              )}
              value={credentials.password}
              inputProps={{
                'data-testid': 'password-input',
                autoComplete: 'off'
              }}
              onChange={({ target: { value } }) => {
                setCredentials({
                  ...credentials,
                  password: value
                });
              }}
              hasError={errors.password}
              errorText={errorMessages.password}
            />
          </div>
        </div>
        <div className='registration-form-account-info-collection__terms-and-conditions'>
          <TDCheckbox
            name='terms-and-conditions'
            label={
              <TDTypography variant='body-s' color='muted'>
                {t(
                  'register_form_account_info_collection_terms_and_conditions_label'
                )}{' '}
                <TDButton
                  as='a'
                  href={TERMS_AND_CONDITIONS_URL[i18n.resolvedLanguage]}
                  variant='text'
                  color='primary'
                  size='xs'
                  target='_blank'
                  className='registration-form-account-info-collection__terms-and-conditions__link'
                >
                  {t(
                    'register_form_account_info_collection_terms_and_conditions_link'
                  )}
                </TDButton>{' '}
                {t(
                  'register_form_account_info_collection_terms_and_conditions_and'
                )}{' '}
                <TDButton
                  as='a'
                  href={PRIVACY_POLICY_URL[i18n.resolvedLanguage]}
                  variant='text'
                  color='primary'
                  size='xs'
                  target='_blank'
                  className='registration-form-account-info-collection__terms-and-conditions__link'
                >
                  {t(
                    'register_form_account_info_collection_privacy_policy_link'
                  )}
                </TDButton>{' '}
                {t(
                  'register_form_account_info_collection_terms_and_conditions_end'
                )}
              </TDTypography>
            }
            checked={isTermsAndConditionsChecked}
            onChange={() =>
              setIsTermsAndConditionsChecked(!isTermsAndConditionsChecked)
            }
            hasError={errors.termsAndConditions}
            errorText={errorMessages.termsAndConditions}
          />
        </div>
      </div>
      <div className='registration-form-account-info-collection__actions'>
        <div className='registration-form-account-info-collection__submit'>
          <TDButton
            color='primary'
            variant='solid'
            fullWidth
            onClick={handleRegister}
            disabled={isLoading}
            data-testid='login-button'
          >
            {isLoading
              ? t('register_form_user_info_collection_registering_button')
              : t('register_form_user_info_collection_register_button')}
          </TDButton>
        </div>
        <div className='registration-form-account-info-collection__or'>
          <TDTypography
            variant='body-m'
            fontWeight='bold'
            className='registration-form-account-info-collection__or-text'
          >
            {t('or')}
          </TDTypography>
        </div>
        <div className='registration-form-account-info-collection__google-login'>
          <GoogleLogin
            shape='pill'
            className='login-page__google-button'
            logo_alignment='center'
            size='large'
            width={352}
            text='continue_with'
            locale={i18n.resolvedLanguage}
            onSuccess={onRegisterWithGoogle}
            onError={(error) => {
              console.error(error);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default RegisterFormAccountInfoCollection;
