import { localStorageKeys } from '../localStorageKeys';

let token =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJndWlkIjoiZTQ1YTQ3MjAtNzAzNi0xMWVkLTk3MjItYzc4ZjBmZThlMGI0IiwidXNlcm5hbWUiOiJ0ZWFjaGVyLTAxLTAxIiwiZW1haWwiOiJ0ZWFjaGVyLTAxLTAxIiwicm9sZV9ndWlkIjoiUjAyIiwibmFtZSI6IlRlYWNoZXIiLCJsYXN0bmFtZSI6InRlc3QiLCJhdmF0YXIiOiIiLCJsYW5nX2lkIjoiZXMiLCJsb2dpbl92ZXJzaW9uIjoxLCJleHAiOjE3MzkyOTAwMzQsInRlbmFudCI6ImRkNmJmNzViLTAyZjEtNDY0My04N2M3LTVhM2QzNTM3MDM0YSIsImx0aSI6ZmFsc2V9.IX_-id-hQkcXxOPQhwLa95GF-3fhc2sQNHHnwkoMhnI';

export function getToken() {
  return localStorage.getItem(localStorageKeys.token) || token;
}

export function setToken(value) {
  localStorage.setItem(localStorageKeys.token, value);
  token = value;
}

export function clearToken() {
  localStorage.removeItem(localStorageKeys.token);
  token = '';
}
