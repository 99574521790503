import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import AuthLayout from '../layouts/auth/AuthLayout';
import LoginPage from '../pages/authentication/login/LoginPage';
import RegisterPage from '../pages/authentication/register/RegisterPage';
import DeviceError from '../pages/device-error/DeviceError';
import GenericError from '../pages/generic-error/GenericError';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import Root from './Root';
import { teacherDashboardRoutes } from './TeacherDashboardRoutes';

const Router = () => {
  const browserRouter = createBrowserRouter([
    {
      path: '/',
      element: <Root />,
      errorElement: <GenericError code={500} />,
      children: [
        {
          element: <PublicRoute />,
          children: [
            {
              element: <AuthLayout />,
              children: [
                {
                  path: '/login',
                  element: <LoginPage />
                },
                {
                  path: '/login-qa',
                  element: <LoginPage forceDisableAnalytics={true} />
                },
                {
                  path: '/register',
                  element: <RegisterPage />
                }
              ]
            }
          ]
        },
        {
          path: '/device-error',
          element: <DeviceError />
        },
        {
          element: <PrivateRoute />,
          children: teacherDashboardRoutes
        }
      ]
    }
  ]);

  return <RouterProvider router={browserRouter} />;
};

export default Router;
