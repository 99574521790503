import * as Sentry from '@sentry/browser';
import posthog from 'posthog-js';
import { PostHogProvider, usePostHog } from 'posthog-js/react';
import { Fragment, useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Onboarding from '../pages/teacherDashboard/onboarding/Onboarding';
import { AuthContext } from '../utils/contexts/AuthContext';
import { useCourse } from '../utils/contexts/CourseContext';
import { getOrganization as getOrganizationData } from '../utils/crud/organization';
import { getDeviceType, isDeviceSupported } from '../utils/helpers/device';
import { useLocalStorage } from '../utils/hooks/useLocalStorage';
import { localStorageKeys } from '../utils/localStorageKeys';
import './TeacherDashboardWrapper.scss';

const IS_LOCALHOST = window.location.href.indexOf('localhost') > -1;

const TeacherDashboardWrapper = () => {
  const { isLoggedIn, user } = useContext(AuthContext);
  const { getItem } = useLocalStorage();
  const ph = usePostHog();
  const { courseGuid } = useParams();
  const navigate = useNavigate();
  const { selectedCourse } = useCourse();

  // TODO: convert to api based environment detection
  //   prod
  const [userWithAnalyticsProperties, setUserWithAnalyticsProperties] =
    useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isProductionEnvironment, setIsProductionEnvironment] = useState(false);

  const [isPostHogInitialized, setIsPostHogInitialized] = useState(false);
  const [isSegmentInitialized, setIsSegmentInitialized] = useState(false);
  const location = useLocation();

  const initializePostHog = () => {
    if (isLoggedIn) {
      if (!isPostHogInitialized) {
        posthog.init(import.meta.env.VITE_PUBLIC_POSTHOG_KEY, {
          api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST,
          autocapture: !Boolean(user?.is_demo),
          disable_session_recording: Boolean(user?.is_demo)
        });

        setIsPostHogInitialized(true);
      }

      ph.identify(user?.guid, userWithAnalyticsProperties);
      return;
    }

    ph.reset();
  };

  const initializeSentryUser = () => {
    if (Sentry.getClient() && user) {
      Sentry.setUser(user);
    }
  };

  const initializeSegment = (isProduction) => {
    let isAnalyticsInitialized = false;
    if (analytics && typeof analytics?.load === 'function') {
      if (!isSegmentInitialized) {
        const siteId = isProduction
          ? import.meta.env.VITE_SEGMENT_SITE_ID
          : import.meta.env.VITE_SEGMENT_SITE_ID_DEV;

        analytics?.load(siteId, {
          integrations: {
            'Segment.io': { apiHost: segmentAPI + '/v1' }
          }
        });
        setIsSegmentInitialized(true);
        isAnalyticsInitialized = true;
      }
      if (isLoggedIn && !user?.is_demo) {
        analytics?.identify(user?.guid, userWithAnalyticsProperties);
      }
    }

    // attempt to initialize segment again in case it failed after 5 seconds
    if (!isAnalyticsInitialized && !isSegmentInitialized) {
      setTimeout(() => initializeSegment(isProduction), 5000);
    }
  };

  const getOrganization = async () => {
    setIsLoading(true);

    const org = await getOrganizationData();
    setIsProductionEnvironment(org?.tenant?.isProduction);

    setIsLoading(false);
  };

  const checkSupportedDevice = () => {
    if (!isDeviceSupported()) {
      navigate('/device-error', { replace: true });
    }
  };

  const updateLanguageOfHTML = () => {
    document.documentElement.lang = getItem(localStorageKeys.language);
  };

  useEffect(() => {
    checkSupportedDevice();
    getOrganization();
  }, []);

  useEffect(() => {
    if (user) {
      setUserWithAnalyticsProperties({
        ...user,
        screen_resolution: `${screen.width}x${screen.height}`,
        browser_resolution: `${window.innerWidth}x${window.innerHeight}`,
        device: getDeviceType()
      });
    }
  }, [user]);

  useEffect(() => {
    if (isLoading) return; // Guard until organization data is fetched to know if production tenant or not
    updateLanguageOfHTML();

    localStorage.setItem(
      'bb_is_production',
      isProductionEnvironment ? '1' : '0'
    );

    const forceDisableAnalytics = localStorage.getItem('bb_disable_analytics');

    if (IS_LOCALHOST) return;

    if (!IS_LOCALHOST && !forceDisableAnalytics) {
      initializeSegment(isProductionEnvironment);
    }

    const IS_DEV_QA_ENVIRONMENT = !isProductionEnvironment && !IS_LOCALHOST;

    if (IS_DEV_QA_ENVIRONMENT) {
      return;
    }

    // ONLY FOR PRODUCTION USERS
    // PostHog is used for feature flags. In order to view the same view as the user, we need to keep it enabled.
    initializePostHog();
    // All incidents on production for all users are sent to sentry
    initializeSentryUser();
  }, [isLoggedIn, isProductionEnvironment, user?.is_demo, isLoading]);

  useEffect(() => {
    if (selectedCourse?.guid && !courseGuid) {
      navigate(
        `/${selectedCourse.guid}${window.location.pathname}${window.location.search}`
      );
    }
  }, [selectedCourse, courseGuid]);

  // TODO: remove this once we have a better way to handle the loading state
  if (!selectedCourse?.guid || !courseGuid) {
    return <div />;
  }

  if (!isProductionEnvironment || IS_LOCALHOST) {
    return (
      <Fragment>
        <ToastContainer />
        <Onboarding>
          <Outlet />
        </Onboarding>
      </Fragment>
    );
  }

  // FOR PRODUCTION ENVIRONMENTS
  return (
    <PostHogProvider client={posthog}>
      <ToastContainer />
      <Onboarding>
        <Outlet />
      </Onboarding>
    </PostHogProvider>
  );
};

export default TeacherDashboardWrapper;
