import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TDButton, TDInputText, TDTypography } from '../../../../atoms/TDUILib';
import useRegisterPage from '../useRegisterPage';
import './RegisterFormEmailVerification.scss';

// 30 seconds in milliseconds
const INTERVAL_TO_RESEND_CODE = 30000;

const RegisterFormEmailVerification = () => {
  const { t } = useTranslation();
  const {
    onResendCode,
    onValidateCode,
    verificationCodeError,
    timeOfLastCodeResend
  } = useRegisterPage();

  const [code, setCode] = useState('');
  const [canResendCode, setCanResendCode] = useState(false);
  const [
    numberOfSecondsLeftUntilCanResendCode,
    setNumberOfSecondsLeftUntilCanResendCode
  ] = useState(INTERVAL_TO_RESEND_CODE / 1000);

  useEffect(() => {
    setCanResendCode(
      timeOfLastCodeResend + INTERVAL_TO_RESEND_CODE < Date.now()
    );
    setNumberOfSecondsLeftUntilCanResendCode(
      Math.floor(
        (timeOfLastCodeResend + INTERVAL_TO_RESEND_CODE - Date.now()) / 1000
      ) || 0
    );
  }, [timeOfLastCodeResend]);

  useEffect(() => {
    let interval;
    interval = setInterval(() => {
      if (timeOfLastCodeResend + INTERVAL_TO_RESEND_CODE > Date.now()) {
        setCanResendCode(false);
        setNumberOfSecondsLeftUntilCanResendCode(
          Math.floor(
            (timeOfLastCodeResend + INTERVAL_TO_RESEND_CODE - Date.now()) / 1000
          ) || 0
        );
      } else {
        setCanResendCode(true);
        clearInterval(interval);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [canResendCode]);

  const handleResendCode = () => {
    if (canResendCode) {
      onResendCode();
    }
  };

  const handleValidateCode = () => {
    onValidateCode(code);
  };

  return (
    <div className='register-form-email-verification__container'>
      <div>
        <div>
          <TDTypography
            variant='body-m'
            className='register-form-email-verification__container__description'
          >
            {t('register_email_verification_description')}
          </TDTypography>
        </div>
        <TDInputText
          label={t('register_email_verification_code_label')}
          placeholder={t('register_email_verification_code_placeholder')}
          onChange={({ target: { value } }) => setCode(value)}
          hasError={!!verificationCodeError}
          errorText={verificationCodeError}
        />
        <div className='register-form-email-verification__resend-code-button-container'>
          <TDButton
            variant='text'
            size='xs'
            color='primary'
            className={clsx(
              'register-form-email-verification__resend-code-button',
              !canResendCode &&
                'register-form-email-verification__resend-code-button--disabled'
            )}
            onClick={handleResendCode}
            disabled={!canResendCode}
          >
            {canResendCode
              ? t('register_email_verification_resend_code')
              : `Send code again in ${numberOfSecondsLeftUntilCanResendCode}...`}
          </TDButton>
        </div>
      </div>

      <TDButton onClick={handleValidateCode}>
        {t('register_email_verification_validate_code')}
      </TDButton>
    </div>
  );
};

export default RegisterFormEmailVerification;
