import consoleLog from './helpers/consoleLog';
import { formatNumericDateWithTime } from './helpers/dateFormatters';
import { localStorageKeys } from './localStorageKeys';

export const AnalysisSegment = {
  // Segment ------------------------------------------------------
  /* eslint-disable */
  SEGMENT_EVENTS: {
    Sign_Up_Form_Viewed: 'Sign Up Form Viewed',
    Sign_Up_Completed: 'Sign Up Completed',
    Sign_Up_Failed: 'Sign Up Failed',
    Sign_Up_Profile_Form_Viewed: 'Sign Up Profile Form Viewed',
    Login_Completed: 'Login Completed',
    Error_Page_Viewed: 'Error Page Viewed',
    Class_Page_Viewed: 'Class Page Viewed',
    Content_Page_Viewed: 'Content Page Viewed',
    Students_Analysis_Page_Viewed: 'Students Analysis Page Viewed',
    Student_Profile_Viewed: 'Student Profile Viewed',
    Student_Profile_Button_Clicked: 'Student Profile Button Clicked',
    XRay_Page_Viewed: 'XRay Page Viewed',
    XRay_Student_Detail_Viewed: 'XRay Student Detail Viewed',
    Practice_Page_Viewed: 'Practice Page Viewed',
    Students_Analysis_Page_Order_Changed:
      'Students Analysis Page Order Changed',
    Students_Analysis_Page_Time_Changed: 'Students Analysis Page Time Changed',
    See_As_Student_Button_Clicked: 'See As Student Button Clicked',
    GAME_OPENED: 'Game Opened',
    Students_Management_Page_Viewed: 'Students Management Page Viewed',
    Students_Management_Page_Order_Changed:
      'Students Management Page Order Changed',
    Students_Management_Passwords_Viewed:
      'Students Management Passwords Viewed',
    Add_Students_Pop_Up_Opened: 'Add Students Pop Up Opened',
    Add_Students_Pop_Up_Closed: 'Add Students Pop Up Closed',
    Edit_Students_Pop_Up_Opened: 'Students Edition Pop Up Opened',
    Students_Added: 'Students Added',
    Students_Added_Error: 'Students Addition Failed',
    Student_Deleted: 'Student Deleted',
    Student_Deleted_Error: 'Student Deleted Failed',
    Students_Profile_LO_Seen: 'Students Profile LO Seen',
    Students_Edited: 'Students Edited',
    Students_Edition_Failed: 'Students Edition Failed',
    New_Practice_Button_Clicked: 'New Practice Button Clicked',
    New_Practice_Created: 'New Practice Created',
    New_Practice_Creation_Failed: 'New Practice Creation Failed',
    New_Practice_Topic_Added: 'New Practice Topic Added',
    New_Practice_Topic_Removed: 'New Practice Topic Removed',
    New_Practice_Standard_Selected: 'New Practice Standard Selected',
    Practice_Activity_Deleted: 'Practice Activity Deleted',
    Practice_Activity_Replaced: 'Practice Activity Replaced',
    Practice_Add_Topic_Button_Clicked: 'Practice Add Topic Button Clicked',
    Practice_Topic_Added: 'Practice Topic Added',
    Practice_Play_Button_Clicked: 'Practice Play Button Clicked',
    Practice_Started: 'Practice Started',
    Practice_Finished: 'Practice Finished',
    Practice_Delete_Button_Clicked: 'Practice Delete Button Clicked',
    Practice_Deleted: 'Practice Deleted',
    Practice_Name_Edited: 'Practice Name Edited',
    Practice_Results_Viewed: 'Practice Results Viewed',
    Practice_Aborted: 'Practice Aborted',
    Reports_Page_Viewed: 'Reports Page Viewed',
    Reports_Download_Button_Clicked: 'Reports Download Button Clicked',
    Reports_Download_failed: 'Reports Download failed',
    Reports_Downloaded: 'Reports Downloaded',
    Open_TD_Button_Clicked: 'Open TD Button Clicked',
    Validation_Pop_up_Seen: 'Validation Pop up Seen',
    Student_LO_Validated: 'Student LO Validated',
    Info_Panel_Clicked: 'Info Panel Clicked',
    Log_In_Select_Region_Changed: 'Log In Select Region Changed',
    Log_In_Pop_Up_Region_Viewed: 'Log In Pop Up Region Viewed',
    Log_In_Pop_Up_Region_Confirmed: 'Log In Pop Up Region Confirmed'
  },

  // Segment Properties mapping
  SEGMENT_PROPERTIES_MAPPING: {
    Students_Analysis_Page_Order_Changed: {
      name: 'name',
      lastname: 'surname',
      engagement: 'engagement',
      masteredContent: 'mastered content'
    },
    Students_Management_Passwords_Viewed: {
      name: 'name',
      lastname: 'surname'
    },
    Students_Management_Page_Order_Changed: {
      name: 'name',
      lastname: 'surname'
    },
    Students_Analysis_Page_Time_Changed: {
      1: 'daily',
      7: 'weekly',
      30: 'monthly',
      90: 'termly'
    }
  },

  // Page names
  PAGE_NAMES: {
    Students_Analysis_Page: 'Students Analysis',
    Students_Management_Page: 'Students Management',
    Class_Page: 'Class',
    Quizzes_Page: 'Practice',
    Reports_Page: 'Reports',
    Content_Page: 'Content',
    XRay_Page: 'XRay'
  },
  /* eslint-enable */

  // Función utilitaria para enviar eventos cuando analytics no está inicializado
  waitForAnalyticsAndTrack: async (eventName, properties) => {
    // Creamos una promesa que se resolverá cuando analytics esté listo
    const waitForAnalytics = new Promise((resolve) => {
      window.analytics.ready(() => {
        resolve();
      });

      // Mantenemos el timeout como fallback
      setTimeout(() => {
        consoleLog(
          'Segment track failed - timeout [' + eventName + ']',
          properties
        );
        resolve();
      }, 10000);
    });

    // Cuando analytics esté listo, enviamos el evento
    await waitForAnalytics;
    if (window.analytics?.initialized === true) {
      window.analytics.track(eventName, properties);
    }
  },

  sendSegmentTrackEvent: (eventName, eventProperties) => {
    const isProductionEnvironment =
      Boolean(
        localStorage.getItem(localStorageKeys.isProduction) === '1' ||
          localStorage.getItem(localStorageKeys.isProduction) === 1 ||
          localStorage.getItem(localStorageKeys.isProduction) === true ||
          localStorage.getItem(localStorageKeys.isProduction) === 'true'
      ) || false;
    const user = localStorage.getItem(localStorageKeys.whoAmI)
      ? JSON.parse(localStorage.getItem(localStorageKeys.whoAmI))
      : null;

    const providerLogin =
      localStorage.getItem(localStorageKeys.providerLogin) || '';

    // Obtén el ancho de la pantalla
    const width = screen.width;

    // Obtén la altura de la pantalla
    const height = screen.height;

    // formateamos la fecha de creación del usuario
    const createDateUser = new Date(user?.created_at);
    const formattedDateTimeUser = formatNumericDateWithTime(createDateUser);

    const propsDefault = {
      user_guid: user?.guid,
      role: user?.role_name,
      username: user?.email,
      tenant: window.location.hostname,
      account_type: 'School',
      school_name:
        user?.schools && user?.schools.length > 0 ? user?.schools[0].name : '',
      education_year: '',
      is_demo: user?.is_demo,
      signup_date: formattedDateTimeUser,
      screen_resolution: `${width}x${height}`,
      provider: providerLogin,
      user_agent: navigator.userAgent
    };

    let mergedProps = {
      ...propsDefault,
      ...eventProperties
    };

    // filter out undefined values
    mergedProps = Object.fromEntries(
      Object.entries(mergedProps).filter(([_, value]) => value !== undefined)
    );

    try {
      if (!user?.is_demo && isProductionEnvironment) {
        console.log('analytics initialized', window.analytics?.initialized);

        if (window.analytics?.initialized !== true) {
          AnalysisSegment.waitForAnalyticsAndTrack(eventName, mergedProps);
        } else {
          window.analytics.track(eventName, mergedProps);
        }
      } else {
        consoleLog('Segment track [' + eventName + ']', mergedProps);
      }
    } catch (segmentError) {
      console.error(segmentError);
    }
  }
};
