export default function secureRandom() {
  if (window.crypto && window.crypto.getRandomValues) {
    const array = new Uint32Array(1);
    window.crypto.getRandomValues(array);
    // Normalize the random value to a float between 0 and 1
    return array[0] / (0xffffffff + 1); // Divide by 2^32
  } else {
    // Fallback to Math.random if crypto is unavailable
    console.warn('crypto not available, falling back to Math.random');
    return Math.random();
  }
}
