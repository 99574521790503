import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AnalysisSegment } from '../analysis';
import { AuthContext } from '../contexts/AuthContext';
import { login, oauthLogin } from '../crud/auth';
import { getWhoami } from '../crud/whoAmI';
import { isLayersTenant } from '../helpers/whichTenant';
import { localStorageKeys } from '../localStorageKeys';
import useAnalytics from './useAnalytics';
import { useLocalStorage } from './useLocalStorage';

const useAuth = (forceDisableAnalytics) => {
  const { setItem, getItem, removeItem } = useLocalStorage();
  const { i18n } = useTranslation();
  const { trackRawEvent, resetAnalytics } = useAnalytics();

  // get global user state to manage user
  const { user, setUser, isLoggedIn, setIsLoggedIn } = useContext(AuthContext);
  const searchParams = new URLSearchParams(window.location.search);

  // Function to update search parameters
  const setSearchParams = (params) => {
    // Update the search parameters
    const newSearchParams = new URLSearchParams(params);

    // Update the URL without reloading the page
    window.history.replaceState(null, '', '?' + newSearchParams.toString());
  };

  // Local auth states
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [schoolAssetsPath, setSchoolAssetsPath] = useState('');

  const handleLogin = async (data, provider) => {
    // save token in local storage
    // debugger;
    setItem(localStorageKeys.token, data.token || data.accessToken);
    // save provider login
    setItem(localStorageKeys.providerLogin, provider || '');
    // get source from search params, if it's not defined, set it to 'direct'
    const searchParams = new URLSearchParams(window.location.search);
    const source = searchParams.get('source') || 'direct';
    // hydrate user
    await hydrateUser();

    // set user language
    const userLanguage = getItem(localStorageKeys.language);
    let languageToSet = userLanguage;

    if (
      !userLanguage ||
      typeof userLanguage !== 'string' ||
      userLanguage?.trim() === '' ||
      userLanguage?.trim() === 'null' ||
      userLanguage?.trim() === 'undefined'
    ) {
      // If there is no user language, use the browser's language
      languageToSet = navigator.language || navigator.languages[0];
    } else {
      languageToSet = userLanguage?.trim()?.toLowerCase();
    }

    i18n.changeLanguage(languageToSet);

    setItem(localStorageKeys.traceIsLoginHome, true);
    trackRawEvent(AnalysisSegment.SEGMENT_EVENTS.Login_Completed, {
      provider: provider,
      source
    });

    // redirect to dashboard
    setIsLoggedIn(true);
    setIsLoading(false);
  };

  const hydrateUser = async () => {
    // check for token to hydrate user to avoid unnecessary calls
    if (getItem(localStorageKeys.token) === null) {
      return;
    }

    // get whoAmI
    const whoAmIResponse = await getWhoami();

    // if fail, set user to null
    if (whoAmIResponse?.status === 'error') {
      onLogout();
      return;
    }

    // check user role. If student, logout and redirect to login of game
    if (whoAmIResponse.role_guid === 'R01') {
      onLogout();
      window.location.href = '/login';
      setIsLoading(false);
      return;
    }

    // if user is not teacher, redirect to login admins are not allowed to login
    if (whoAmIResponse.role_guid !== 'R02') {
      removeItem(localStorageKeys.token);
      removeItem(localStorageKeys.providerLogin);
      setError('LOGIN_PERMISSIONS_ERROR');
      setIsLoggedIn(false);
      setIsLoading(false);
      return;
    }

    // save whoAmI
    setItem(localStorageKeys.whoAmI, JSON.stringify(whoAmIResponse));
    setItem(localStorageKeys.userGuid, whoAmIResponse.guid);
    setItem(localStorageKeys.firstName, whoAmIResponse.name);
    setItem(localStorageKeys.lastName, whoAmIResponse.lastname);
    setItem(localStorageKeys.email, whoAmIResponse.email);
    setItem(localStorageKeys.role, whoAmIResponse.role_guid);
    setItem(localStorageKeys.loginTimeStamp, new Date().getTime());
    setItem(localStorageKeys.language, whoAmIResponse.lang_id);
    setItem(localStorageKeys.schoolGuid, whoAmIResponse.schools[0]?.guid);

    // setUser
    setUser(whoAmIResponse);
    setIsLoading(false);
  };

  useEffect(() => {
    const token = searchParams.get('token');
    if (token) {
      //TODO: Fran esto es lo que se me ha ocurrido, no se si es lo mejor, pero entendemos que puede llega un nuevo usuario sin pasar por logout
      // y limpiar posibles datos asociados al usuario anterior
      removeItem(localStorageKeys.currentCourseGuid);
      removeItem(localStorageKeys.currentCourse);
      removeItem(localStorageKeys.schoolGuid);
      setItem(localStorageKeys.token, token);
      let tempSearchParams = new URLSearchParams(searchParams);
      tempSearchParams.delete('token');
      setSearchParams(tempSearchParams);
      hydrateUser(); // This ensures it only runs once when the token is found
    } else if (!isLoggedIn && !isLoading) {
      // Check if there's already a user set to avoid unnecessary hydration
      hydrateUser();
    } else if (!user) {
      hydrateUser();
    } else {
      // If there's a user, it means the user is already hydrated
      setIsLoading(false);
    }
    // Omitting searchParams from dependency array to avoid re-triggers due to its changes
    // But be cautious, as this might skip updates if other dependencies change. Adjust according to your needs.
  }, [isLoggedIn]); // Assuming no other dependencies, adjust as needed.

  const onLoginWithEmailAndPassword = async ({ email, password }) => {
    setIsLoading(true);

    if (isLoading) return;

    if (!email || !password) {
      setError('LOGIN_WRONG_FORMAT');
      setIsLoading(false);
      return;
    }

    const loginResponse = await login({ email, password });

    if (loginResponse.status === 'success') {
      const { data } = loginResponse;
      handleLogin(data, 'userpass');
    } else {
      const errorCode = loginResponse?.error?.code || loginResponse?.errorCode;

      if (errorCode && errorCode >= 500) {
        setError('SERVER_ERROR');
      } else {
        setError('LOGIN_ERROR');
      }
      setIsLoading(false);
    }
  };

  const onLoginWithGoogle = async ({ credential }) => {
    const oauthLoginResponse = await oauthLogin({
      data: {
        identity_provider: 'google',
        token: credential
      },
      disableCatchHandling: true
    });

    if (oauthLoginResponse.status === 'success') {
      const { data } = oauthLoginResponse;
      handleLogin(data, 'google');
    } else {
      setError(
        oauthLoginResponse.data?.error?.errorKey === 'EMAIL_NOT_FOUND'
          ? 'LOGIN_GOOGLE_ERROR_USER_NOT_FOUND'
          : 'LOGIN_GOOGLE_ERROR'
      );
    }
  };

  const onLoginWithLayers = async ({ layersTokenExchangeCode }) => {
    setIsLoading(true);
    const urlHost = window.location.protocol + '//' + window.location.host;

    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

    const urlencoded = new URLSearchParams();
    urlencoded.append('grant_type', 'authorization_code');
    urlencoded.append('client_id', 'blueberry');
    urlencoded.append('code', layersTokenExchangeCode);
    urlencoded.append('redirect_uri', urlHost);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    // remove the code from the search params as it is not needed anymore
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete('code');
    setSearchParams(newSearchParams);

    // exchange the code for a token
    const response = await fetch(
      'https://api.layers.digital/oauth/token',
      requestOptions
    );
    const data = await response.json();

    if (data?.access_token) {
      const oauthLoginResponse = await oauthLogin({
        data: {
          identity_provider: 'layers',
          token: data.access_token
        },
        disableCatchHandling: true
      });

      if (oauthLoginResponse.status === 'success') {
        const { data } = oauthLoginResponse;
        handleLogin(data, 'layers');
      } else {
        setError('LOGIN_LAYERS_ERROR');
        setIsLoading(false);
      }
    } else {
      setError('LOGIN_LAYERS_ERROR');
      setIsLoading(false);
    }

    return data;
  };

  const onLogout = () => {
    // Clear local storage except the production environment flag (if it exists) because it's not related to the user
    const isProductionEnvironment = localStorage.getItem('bb_is_production');
    localStorage.clear();
    isProductionEnvironment &&
      setItem('bb_is_production', isProductionEnvironment); // Keep the production environment flag
    setSearchParams({ user_logout: true });

    // disable redirect to handle logout in the same page in case of login error
    resetAnalytics();
    setUser(null);
    setIsLoggedIn(false);
  };

  useEffect(() => {
    if (forceDisableAnalytics) {
      setItem('bb_disable_analytics', 'true');
      resetAnalytics();
    }
  }, [forceDisableAnalytics]);

  const getValidSchoolPath = (user) => {
    const userSchool = user?.schools[0];
    return userSchool?.school_path || 'default';
  };

  useEffect(() => {
    if (user) {
      setSchoolAssetsPath(getValidSchoolPath(user));
    }
  }, [user]);

  useEffect(() => {
    if (isLayersTenant() && searchParams.get('code')) {
      onLoginWithLayers({ layersTokenExchangeCode: searchParams.get('code') });
    }
  }, [searchParams]);

  return {
    user,
    error,
    isLoading,
    isLoggedIn,
    schoolAssetsPath,
    // Functions
    setError,
    onLogout,
    hydrateUser,
    onLoginWithGoogle,
    onLoginWithEmailAndPassword
  };
};

export default useAuth;
