import { useTranslation } from 'react-i18next';
import TooltipCell from '../TooltipCell/TooltipCell';
import './cellStudentMasteredContent.scss';

const CellStudentMasteredContent = ({
  selectedTimeRange,
  studentMasteredContentData
}) => {
  const {
    masteredKcsPercent,
    masteredKcsInLastDaysPercent,
    forgottenKcsPercent
  } = studentMasteredContentData;

  const restOfMasteredKcsPercent =
    masteredKcsPercent - forgottenKcsPercent - masteredKcsInLastDaysPercent;

  const { t } = useTranslation();

  const PROGRESS_BAR_MINIMUM_PERCENT = 4;

  const MasteredContentTooltipComponent = ({
    forgottenKcsPercent,
    masteredKcsPercent,
    masteredKcsInLastDaysPercent
  }) => {
    return (
      <div className='cell-student-mastered-tooltip'>
        <div className='cell-student-mastered-tooltip__legend cell-student-mastered-tooltip__legend--forgotten'>
          <span />
          {t('student_content_forgotten')} - {forgottenKcsPercent}%
        </div>
        <div className='cell-student-mastered-tooltip__legend cell-student-mastered-tooltip__legend--progress-total'>
          <span />
          {t('student_content_total_progress')} - {masteredKcsPercent}%
        </div>
        <div className='cell-student-mastered-tooltip__legend cell-student-mastered-tooltip__legend--progress-time'>
          <span />
          {selectedTimeRange === 1 && t('student_content_total_day')}
          {selectedTimeRange === 7 && t('student_content_total_week')}
          {selectedTimeRange === 30 && t('student_content_total_month')}
          {selectedTimeRange === 90 && t('student_content_total_term')} -
          {masteredKcsInLastDaysPercent}%
        </div>
        <div className='cell-student-mastered-tooltip__legend cell-student-mastered-tooltip__legend--total'>
          <span />
          {t('student_content_total_course')}
        </div>
      </div>
    );
  };

  const calculateBarWidth = (percent) => {
    return percent <= PROGRESS_BAR_MINIMUM_PERCENT
      ? PROGRESS_BAR_MINIMUM_PERCENT + '%'
      : percent + '%';
  };

  return (
    <div className='cell-student-mastered-content'>
      <TooltipCell
        // open={true}
        title={
          <MasteredContentTooltipComponent
            forgottenKcsPercent={forgottenKcsPercent}
            masteredKcsPercent={masteredKcsPercent}
            masteredKcsInLastDaysPercent={masteredKcsInLastDaysPercent}
          />
        }
        placement='bottom'
      >
        <div className={`cell-student-mastered-content__progress-bar`}>
          <div className='cell-student-mastered-content__progress-bar-bg' />
          <div
            className={`cell-student-mastered-content__progress-bar-percentages ${
              masteredKcsPercent === 100
                ? 'cell-student-mastered-content__progress-bar-percentages--full'
                : ''
            }`}
          >
            {!!forgottenKcsPercent && (
              <div
                className={`cell-student-mastered-content__progress-bar-forgotten`}
                style={{
                  width: calculateBarWidth(forgottenKcsPercent)
                }}
              />
            )}
            {!!restOfMasteredKcsPercent && (
              <div
                className={`cell-student-mastered-content__progress-bar-mastered`}
                style={{
                  width: calculateBarWidth(restOfMasteredKcsPercent)
                }}
              />
            )}
            {!!masteredKcsInLastDaysPercent && (
              <div
                className={`cell-student-mastered-content__progress-bar-mastered-last`}
                style={{
                  width: calculateBarWidth(masteredKcsInLastDaysPercent)
                }}
              />
            )}
          </div>
        </div>
      </TooltipCell>

      <div className='cell-student-mastered-content__delta'>
        {masteredKcsInLastDaysPercent > 0 && '+'}
        {masteredKcsInLastDaysPercent}%
      </div>
    </div>
  );
};

export default CellStudentMasteredContent;
