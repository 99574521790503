import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ACTIVITY_TYPES,
  ActivityBlueberryComponent
} from '../../../../../_practice-components';
import generateAssetURL from '../../../../../utils/helpers/generateAssetURL';
import useAuth from '../../../../../utils/hooks/useAuth';
import useUserSettings from '../../../../../utils/hooks/useUserSettings';
import GameQuizButton from '../components/gameQuizButton/GameQuizButton';
import GameQuizHeader from '../components/gameQuizHeader/GameQuizHeader';
import PlayerCard from '../components/playerCard/PlayerCard';
import QuestionAnswerCounter from '../components/questionAnswerCounter/QuestionAnswerCounter';
import QuestionProgressBar from '../components/questionProgressBar/QuestionProgressBar';
import QuestionTimer from '../components/questionTimer/QuestionTimer';
import './playQuizQuestion.scss';
import usePlayQuizQuestion from './usePlayQuizQuestion';

const CheckIcon = () => {
  return (
    <div className='check-icon'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='45'
        height='45'
        viewBox='0 0 45 45'
        fill='none'
      >
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M36.9508 11.7992C37.6831 12.5314 37.6831 13.7186 36.9508 14.4508L18.2008 33.2008C17.4686 33.9331 16.2814 33.9331 15.5492 33.2008L8.04917 25.7008C7.31694 24.9686 7.31694 23.7814 8.04917 23.0492C8.78141 22.3169 9.96859 22.3169 10.7008 23.0492L16.875 29.2234L34.2992 11.7992C35.0314 11.0669 36.2186 11.0669 36.9508 11.7992Z'
          fill='#57AF82'
        />
      </svg>
    </div>
  );
};

const PlayQuizQuestion = ({
  quizGuid,
  questions,
  currentQuestion,
  numberOfQuestions,
  gotoRanking,
  sendShowSolution,
  setCurrentQuestion,
  totalPlayers,
  playerList,
  correctAnswers,
  totalAnswers,
  setSeconds,
  seconds,
  syncTimestamp,
  playSoundEffect,
  closeGame
}) => {
  const DEFAULT_AVATAR = generateAssetURL('default_single_avatar.png');

  const { isTimeFinished, handleTimeEnd, correctPlayerList } =
    usePlayQuizQuestion({
      sendShowSolution,
      currentQuestion,
      setCurrentQuestion,
      playerList,
      correctAnswers,
      playSoundEffect
    });

  const { t } = useTranslation();
  const { schoolAssetsPath } = useAuth();

  const { getPreviewLanguageSettings } = useUserSettings();
  const [activityLanguage, setActivityLanguage] = useState(null);

  const sadAvatar = generateAssetURL({
    asset: 'avatar_sad.svg',
    schoolAssetsPath
  });

  useEffect(() => {
    setActivityLanguage(getPreviewLanguageSettings());
  }, []);

  return (
    currentQuestion !== null && (
      <div className='play-quiz-question'>
        <div className='play-quiz-question__header'>
          <GameQuizHeader
            quizGuid={quizGuid}
            closeGame={closeGame}
            leftComponent={
              <div className='play-quiz-question__answers-count'>
                <QuestionAnswerCounter
                  totalAnswers={totalAnswers}
                  totalPlayers={totalPlayers}
                  isTimeFinished={isTimeFinished}
                />
              </div>
            }
            centerComponent={
              <div className='play-quiz-question__timer'>
                <QuestionTimer
                  onEnd={handleTimeEnd}
                  forceTimeFinish={isTimeFinished}
                  setSeconds={setSeconds}
                  seconds={seconds}
                  syncTimestamp={syncTimestamp}
                  playSoundEffect={playSoundEffect}
                  currentQuestion={currentQuestion}
                />
              </div>
            }
            rightComponent={
              <GameQuizButton
                text={t('quiz_continue_button')}
                onClick={isTimeFinished ? gotoRanking : handleTimeEnd}
                outline={!isTimeFinished}
              />
            }
          />
        </div>
        <div className='play-quiz-question__content'>
          <div className='play-quiz-question__question-wrapper'>
            <div className='play-quiz-question__progress-bar'>
              <QuestionProgressBar
                currentQuestion={currentQuestion + 1}
                totalQuestions={numberOfQuestions}
                isFinished={isTimeFinished}
              />
            </div>

            <div className='play-quiz-question__question'>
              <div className='play-quiz-question__question-number'>
                {currentQuestion + 1}
              </div>

              <div className='play-quiz-question__question-preview'>
                {activityLanguage && (
                  <ActivityBlueberryComponent
                    activityType={ACTIVITY_TYPES.QUIZ}
                    activity={
                      questions[currentQuestion]?.question?.type === 'scaffold'
                        ? questions[currentQuestion]?.question?.questions[0]
                        : questions[currentQuestion]?.question
                    }
                    disabledQuestion={true}
                    isHideFooter={true}
                    showAllFeedback={false}
                    showSolutionQuestionQuizPreview={isTimeFinished}
                    mathRenderEngine='katex'
                    pathAssetsSchool={schoolAssetsPath}
                    uiLanguage={activityLanguage}
                  />
                )}
              </div>
            </div>
          </div>

          {isTimeFinished && (
            <div className='play-quiz-question__results-wrapper'>
              <div
                className={
                  correctAnswers?.length > 0
                    ? 'play-quiz-question__results'
                    : 'play-quiz-question__results-error'
                }
              >
                {correctAnswers?.length > 0 ? (
                  <>
                    <CheckIcon />
                    <div className='play-quiz-question__results-list'>
                      {correctPlayerList?.map((item) => (
                        <PlayerCard
                          key={item.user_guid}
                          name={item.name}
                          avatar={item.avatar ? item.avatar : DEFAULT_AVATAR}
                          isConnected={item.is_online}
                          size={
                            correctPlayerList?.length < 5 ? 'large' : 'small'
                          }
                        />
                      ))}
                    </div>
                  </>
                ) : (
                  <div className='play-quiz-question__results-list-error'>
                    <img className='image-error' src={sadAvatar} alt='' />
                    <div>
                      <span className='play-quiz-question__results-list__container-text'>
                        {t('quiz_no_answers')}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    )
  );
};

export default PlayQuizQuestion;
