import './TDProgressBar.scss';

const TDProgressBar = ({ value, className }) => {
  return (
    <div className={`td-progress-bar ${className || ''}`}>
      <div className='td-progress-bar__bar'>
        <div
          className='td-progress-bar__bar__fill'
          style={{ width: `${value}%` }}
        />
      </div>
    </div>
  );
};

export default TDProgressBar;
