import { CircularProgress } from '@mui/material';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/outline/download.svg';
import GenericToast from '../../../atoms/GenericToast/GenericToast';
import { TDHeaderButton, TDSelect } from '../../../atoms/TDUILib';
import PageTitleWithTooltip from '../../../components/pageTitleWithTooltip/PageTitleWithTooltip';
import PageWithHeader from '../../../layouts/pageWithHeader/PageWithHeader';
import './ListReports.scss';
import UserReport from './components/userReport/UserReport';
import UserReportPDF from './components/userReportPDF/UserReportPDF';
import useListReports from './useListReports';

import { AnalysisSegment } from '../../../utils/analysis';
import consoleLog from '../../../utils/helpers/consoleLog';
import useAnalytics from '../../../utils/hooks/useAnalytics';
import ListReportsTooltip from './components/listReportsTooltip/ListReportsTooltip';

const ListReports = () => {
  const { trackEvent } = useAnalytics();

  const { t } = useTranslation();
  const [pdfTranslations, setPdfTranslations] = useState({
    reportDate: t('report_pdf_date'),
    progressTimeRangeTitle: t('report_pdf_progress_day_title'),
    activitiesTitle: t('report_pdf_activities_title'),
    activitiesCorrect: t('report_pdf_activities_correct'),
    activitiesIncorrect: t('report_pdf_activities_incorrect'),
    activitiesAbandoned: t('report_pdf_activities_abandoned'),
    progressSummaryTitle: t('report_pdf_progress_summary_title'),
    difficultiesTitle: t('report_pdf_difficulties_title'),
    difficultiesInfo: t('report_pdf_difficulties_info'),
    masteredTitle: t('report_pdf_mastered_title'),
    masteredInfo: t('report_pdf_mastered_info'),
    forgottenTitle: t('report_pdf_forgotten_title'),
    forgottenInfo: t('report_pdf_forgotten_info'),
    noContent: t('report_pdf_no_content')
  });

  const [zipLoading, setZipLoading] = useState(false);

  const {
    report,
    fullStudentsReport,
    isLoading,
    selectedCourse,
    selectedTimeRange,
    selectedOrderBy,
    setSelectedTimeRange,
    setSelectedOrderBy,
    SORT_OPTIONS,
    TIME_RANGE_OPTIONS
  } = useListReports(t);

  const updatePdfTranslations = () => {
    const newPdfTranslations = {
      ...pdfTranslations,
      progressTimeRangeTitle:
        selectedTimeRange?.value === '1'
          ? t('report_pdf_progress_day_title')
          : selectedTimeRange?.value === '7'
          ? t('report_pdf_progress_week_title')
          : selectedTimeRange?.value === '30'
          ? t('report_pdf_progress_month_title')
          : t('report_pdf_progress_term_title')
    };
    setPdfTranslations(newPdfTranslations);
  };

  useEffect(() => {
    if (!selectedTimeRange?.value) return;
    updatePdfTranslations();
  }, [selectedTimeRange]);

  const today = new Date();
  const currentFormattedDate =
    today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear();

  const createAndDownloadZip = useCallback(
    async (retryAttempt = 0) => {
      if (!fullStudentsReport?.length || zipLoading) return;

      try {
        setZipLoading(true);
        const zip = new JSZip();

        // Small delay to ensure the state updates before proceeding
        await new Promise((resolve) => setTimeout(resolve, 0));

        const pdfPromises = fullStudentsReport.map(async (user) => {
          const blob = await pdf(
            <UserReportPDF
              currentDate={currentFormattedDate}
              user={user}
              className={selectedCourse?.school_group_name}
              translations={pdfTranslations}
            />
          ).toBlob();
          zip.file(
            `BlueberryMath-${t('reports_title')}_${user.username}.pdf`,
            blob
          );
        });

        await Promise.all(pdfPromises);
        const content = await zip.generateAsync({ type: 'blob' });
        saveAs(
          content,
          `BlueberryMath-${t(
            'reports_title'
          )}-${selectedCourse?.school_group_name
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .replace(/\s+|\W/g, '')}.zip`
        );
      } catch (error) {
        console.error('Error generating PDFs:', error);

        // Retry once if it's the first attempt
        if (retryAttempt === 0) {
          consoleLog('Retrying PDF generation...');
          await createAndDownloadZip(1);
          return;
        }

        // Show error toast only if retry also failed
        toast(
          <GenericToast
            type='warning'
            title={t('reports_download_error_title')}
            text={t('reports_download_error_info')}
          />,
          {
            position: 'bottom-left',
            autoClose: 4000,
            hideProgressBar: true,
            className: 'generic-toast--warning'
          }
        );
        trackEvent(AnalysisSegment.SEGMENT_EVENTS.Reports_Download_failed, {
          program_id: selectedCourse?.program_guid,
          class_name: selectedCourse?.school_group_name,
          number_of_students: selectedCourse?.users?.filter(
            (user) => user.role_guid === 'R01'
          ).length
        });
      } finally {
        setZipLoading(false);
        trackEvent(AnalysisSegment.SEGMENT_EVENTS.Reports_Downloaded, {
          program_id: selectedCourse?.program_guid,
          class_name: selectedCourse?.school_group_name,
          number_of_students: selectedCourse?.users?.filter(
            (user) => user.role_guid === 'R01'
          ).length
        });
      }
    },
    [
      fullStudentsReport,
      currentFormattedDate,
      selectedCourse,
      pdfTranslations,
      t,
      zipLoading
    ]
  );

  const handleDownloadClick = useCallback(() => {
    trackEvent(AnalysisSegment.SEGMENT_EVENTS.Reports_Download_Button_Clicked, {
      program_id: selectedCourse?.program_guid,
      class_name: selectedCourse?.school_group_name,
      number_of_students: selectedCourse?.users?.filter(
        (user) => user.role_guid === 'R01'
      ).length
    });

    // Forces button to be disabled from the moment we click it
    setZipLoading(true);
    setTimeout(createAndDownloadZip, 0);
  }, [createAndDownloadZip]);

  return (
    <div className='reports-page'>
      <PageWithHeader
        left={
          <PageTitleWithTooltip
            title={t('reports_title')}
            testId='reports-page-heading'
          />
        }
        right={
          <div className='reports-page__filters-container'>
            <TDSelect
              options={SORT_OPTIONS}
              selectedOption={selectedOrderBy}
              onChange={setSelectedOrderBy}
              placeholder={t('order_by')}
              optionsTitle={t('order_by')}
            />
            <TDSelect
              options={TIME_RANGE_OPTIONS}
              selectedOption={selectedTimeRange}
              onChange={setSelectedTimeRange}
              placeholder={t('filter_by')}
              optionsTitle={t('filter_by')}
            />
            <TDHeaderButton
              onClick={handleDownloadClick}
              icon={<DownloadIcon />}
              border
              small
              disabled={isLoading || zipLoading}
            >
              {zipLoading
                ? t('reports_loading_button')
                : t('reports_download_button')}
            </TDHeaderButton>
          </div>
        }
        tooltip={<ListReportsTooltip />}
        pageName={AnalysisSegment.PAGE_NAMES.Reports_Page}
      >
        <div className='reports-container'>
          <div className='reports-page__table'>
            <div className='reports-page__table-header'>
              <div>{t('reports_student_name')}</div>
              <div className='reports-page__table-header__text'>
                {t('reports_performed_activities')}
              </div>
              <div className='reports-page__table-header__text'>
                {t('reports_practice_time')}
              </div>
            </div>

            {isLoading ? (
              <div className='reports-page__loading'>
                <CircularProgress />
              </div>
            ) : (
              report.students?.map((student, index) => {
                return (
                  <UserReport
                    key={student?.guid || index}
                    user={student}
                    courseGuid={selectedCourse}
                  />
                );
              })
            )}
          </div>
        </div>
      </PageWithHeader>
    </div>
  );
};

export default ListReports;
